@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form_buttoms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.submit_login {
  display: flex;
  justify-content: center;
}
.forgot_password {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}