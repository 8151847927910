@import "../../../Styles/theme.scss";

.desktop {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.desktop_top {
  display: flex;
  flex-direction: row;
}

.desktop_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 24px;
}

.desktop_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 24px;
}

.desktop_bottom_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile_header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 110;
}

.mobile_body {
  background-color: white;
}