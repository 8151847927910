@import "../../Styles/theme.scss";

.page_title {
  font-size: $heading2;
  font-weight: $mediumWeight;
  margin-bottom: 8px;
}

.user_info {
  color: $neutral5;
  font-size: $headerBody;
}

.dashboard_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
}

@media (max-width: 1194px) {
  .dashboard_header_container {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
  .page_title {
    margin-bottom: 16px;
  }
}
