@import "../../Styles/theme.scss";

.behaviour {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $neutral7;
}

.behaviour_top {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
}

.behaviour_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.risk_rating_mobile {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 105px;
}
.behaviour_bottom_day_view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  padding-bottom: 30px;
}

// Class Props - MealTimeWindow
.meal_window_tip {
  margin-left: 32px;
  margin-right: 10px;
}

// Divider component
.divider {
  border: 0.5px solid $neutral2;
  margin-right: 32px;
}

// Eating And Drinking Window Component
.eating_and_drinking_window_container {
  min-width: 290px;
  max-width: 290px;
  padding-right: 32px;
  box-sizing: border-box;
}

.time_period {
  color: #656565;
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
  margin-top: 12px;
  margin-bottom: 24px;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.no_events {
  font-size: 20px;
  font-weight: $mediumWeight;
  color: $neutral5;
}

@media (max-width: $tablet-break-point) {
  .behaviour_context {
    margin-right: 0;
    flex-direction: column;
  }
  .behaviour_bottom_day_view {
    flex-direction: column;
    width: 100%;
    overflow-x: unset;
  }
  .eating_and_drinking_window_container {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding-right: unset;
  }
  .divider {
    margin-right: unset;
    margin-bottom: 20px;
  }
  .no_events {
    font-size: 20px;
    font-weight: $regular;
    margin-bottom: 24px;
  }
}

@media (max-width: $mobile-break-point) {
  .behaviour_title {
    font-size: $heading5;
    font-weight: $mediumWeight;
    margin-bottom: unset;
  }
}

@media (max-width: 400px) {
  .behaviour_header {
    flex-direction: column;
  }
}
