@import "../../Styles/theme.scss";

.page {
  min-height: calc(100vh - #{$header-height});
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: $header-height;
  background-color: $neutral1;
}

.page_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 14px;
}

.page_content_desktop {
  width: calc(100% - #{$side-drawer-width});
  margin-left: $side-drawer-width;
  flex: 1;
}

@media (max-width: $mobile-break-point) {
  .page_content {
    padding: 0;
  }
}
