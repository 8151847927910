@import "../../Styles/theme.scss";

.container {
  display: flex;
  width: 100%;
  flex: 1;
  background-color: $white;
}

.toggle {
  align-self: center;
  margin-bottom: 24px;
  margin-top: 12px;
}

.eventlogContainer {
  position: relative;
  flex: 1;
  margin-right: 36px;
  max-width: 300px;
  min-height: 800px;
}

.no_data_insights {
  flex: 1;
  h2 {
    font-size: $subHeading;
    margin: 0 0 16px 0;
  }
}

.insights_context {
  font-size: $bodySmall;
  color: $neutral7;
  margin-bottom: 36px;
}

.insights_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 400px));
  gap: 24px;
  align-items: flex-start;
}

@media (max-width: $mobile-break-point) {
  .container {
    flex-direction: column;
  }
  .eventlogContainer {
    margin-right: 0;
    max-width: unset;
  }
}
