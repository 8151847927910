@import "../../Styles/theme.scss";

.container {
  display: flex;
}

.item {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: $heading2;
    font-weight: $mediumWeight;
    margin-bottom: 0;
  }
  div {
    font-size: $bodySmall;
  }
}

.line {
  height: 48px;
  border: 1px solid $neutral2;
  margin: 0 27px;
}
