@import "../../Styles/theme.scss";

.page {
  padding: 0;
}

.info {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px $desktop-padding;
  box-sizing: border-box;
  border-bottom: 3px solid $primary6;
}

.content {
  padding: $desktop-padding;
  box-sizing: border-box;
  width: 100%;
  h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $heading-3;
    margin: 0;
  }
}

.option {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 48px;
  .user_roles {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  button {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.serviceUser {
  display: flex;
  align-items: center;
}

@media (max-width: $mobile-break-point) {
  .serviceUser {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .info {
    padding: $mobile-padding;
    flex-direction: column;
  }
  .content {
    padding: $mobile-padding;
  }
  .option {
    flex-direction: column;
    align-items: flex-start;
  }
}
