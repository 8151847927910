@import "../../Styles/theme.scss";

.date_input_group {
  padding-left: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.date_selection {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none;
  font-size: $bodySmall;
  background-color: #fafafa;
  color: $neutral5;
  text-transform: uppercase;
  font-family: Inter;
  text-align: left;
}

input[type="date"]::-webkit-date-and-time-value {
  // Necessary for Safari
  text-align: left;
  color: $neutral5;
}
input[type="date"]::-webkit-input-placeholder {
  color: $neutral5;
}
// change the position of the datepicker/calendar icon
// https://stackoverflow.com/questions/63121577/how-do-i-change-the-position-of-the-datepicker-calendar-icon-in-my-bootstrap-for
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
