@import "../../Styles/theme.scss";

.notifications_list_container {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.underline_container {
  align-self: center;
  line-height: 0;
}

.no_more_notifications_text {
  align-self: center;
  color: $neutral5;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
}
