@import "../../Styles/theme.scss";

.guestPage {
  width: 100%;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  position: relative;
}

.whiteBackground {
  background-color: $white;
}

.guestPage_header {
  width: 100%;
  font-size: $heading2; // $heading-2 from old variables file;
  display: flex;
  height: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.guestPage_content {
  padding: $desktop-padding $desktop-padding $desktop-padding $desktop-padding;
  padding: $mobile-padding;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.guestPage_content_innner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(
    100vh - 160px + $desktop-padding + $desktop-padding - 48px - 20px
  ); // 160px from the .guestPage_header height
  width: 100%;
  p {
    text-align: center;
  }
}

.guestPage_footer {
  padding-right: 40px;
  padding-left: 40px;
  text-align: center;

  a {
    color: $primary6;
    text-decoration: underline;
  }

  p {
    font-size: $bodySmall;
  }

  p:nth-child(2) {
    padding-bottom: 24px;
  }
}

.privacyButton {
  padding: 0;
  margin-bottom: 2px;
}

@media (max-width: $mobile-break-point) {
  .guestPage {
    flex-direction: column;
  }
  .guestPage_content {
    padding: $mobile-padding;
    padding-top: $desktop-padding;
  }
  .guestPage_content_innner {
    // This takes into the header height
    min-height: calc(100vh - 160px + ($mobile-padding * 2));
  }
  .guestPage_footer {
    padding-right: 24px;
    padding-left: 24px;
    p:nth-child(2) {
      padding-bottom: 12px;
    }
  }
}
