@import "../../Styles/theme.scss";

.go_back_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-weight: $regular;
  font-size: $bodySmall;
  cursor: pointer;

  width: 65px;
  background: $white;
  border: 1px solid $neutral2;
  border-radius: 4px;
  padding: 10px 14px;
  color: $neutral7;
  margin-right: 14px;
}

.flex {
  flex: 1;
}
