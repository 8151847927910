@import "../../Styles/theme.scss";

button {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
  background-color: $neutral2;
  width: 264px;
  border-radius: 17px;
  padding: 3px;
}

.option {
  background-color: $neutral2;
  border-radius: 17px;
  flex: 1;
  padding: 6px 0px;
  border: none;
}

.option_focused {
  @extend .option;
  background-color: $white;
}

.text {
  color: #858585;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
}

.text_focused {
  @extend .text;
  color: $neutral7;
}
