@import "../../Styles/theme.scss";

.loading_bubbles_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 140px;
}

.bubble {
  border-radius: 50%;
  background-color: $neutral2;
  width: 11px;
  height: 11px;
  margin: 11px;

  animation: expand 0.75s ease-in-out infinite;
  border-radius: 20px;
  display: inline-block;
  transform-origin: center center;
}

.bubble:nth-child(2) {
  animation-delay: 260ms;
}

.bubble:nth-child(3) {
  animation-delay: 460ms;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.55);
  }
}

@media (max-width: $mobile-break-point) {
  .loading_bubbles_container {
    flex-direction: column;
    min-height: 140px;
    min-width: auto;
  }
}
