@import "../../../Styles/theme.scss";

.timeframe {
  align-self: center;
  flex: 1;
  h2 {
    margin-top: 64px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  h1 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  p {
    color: $neutral6;
    margin-bottom: 32px;
    max-width: 360px;
  }
}

.backdrop {
  position: absolute;
  opacity: 0.4;
  z-index: 600;
}

.required {
  color: $risk6;
}
