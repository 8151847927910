.container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h3 {
    margin: 0;
  }
}

.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.filter_category {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
}

.filter_item {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.results {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
