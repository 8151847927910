@import "../../Styles/theme.scss";

.mobileDrawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 48px;
  border-radius: 28px 28px 0px 0px;
  margin-left: 65px; // the width of the navigation drawer

  .content {
    padding: 32px;
    overflow-y: auto;
  }
}

.content {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.content::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.close_guide {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  padding: 16px;
}

.close_guide_icon {
  cursor: pointer;
}

.close_guide_icon:hover {
  color: $risk3;
}

.open {
  transform: translateY(0);
}

.closed {
  transform: translateY(100%);
}

.close_guide {
  position: relative;
  top: 16px;
  left: 0px;
}

// min width
@media (max-width: 601px) {
  .mobileDrawer {
    margin-left: 0px;
  }
}
