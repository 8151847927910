@import "../../Styles/theme.scss";

.nav_tabs,
.nav_tabs_sentences,
.nav_tabs_graph {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: white;
  box-sizing: border-box;

  select {
    text-align-last: center;
  }
}

.nav_tabs {
  padding: 6px 30px;
  border-bottom: 4px solid $neutral1;
  div {
    font-size: $body;
  }
}

.nav_tabs_sentences {
  padding: 0px;
  div {
    font-size: $body;
  }
}

@media (max-width: 900px) {
  .nav_tabs {
    padding: 12px 10px;
  }
}

@media (max-width: $mobile-break-point) {
  .nav_tabs,
  .nav_tabs_sentences,
  .nav_tabs_graph {
    padding-top: 14px;
    padding-bottom: 14px;

    select {
      border: unset;
      background-color: white;
      font-size: 16px;
      color: #143c46;
      font-size: 1.1rem;
    }
  }

  .nav_tabs {
    justify-content: center;
    border-bottom: none;
  }

  .nav_tabs_sentences,
  .nav_tabs_graph {
    justify-content: left;
  }
}
