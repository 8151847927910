@import "../../Styles/theme.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0.9;
  }
}

.fade_in {
  animation: fadeIn 150ms ease-in-out; // a transition effect with a slow start and end
}
.fade_out {
  animation: fadeOut 200ms ease-out;
}

.risk_status_outer {
  position: fixed;
  z-index: 600;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.risk_status_inner {
  position: relative;
  z-index: 500;
  width: 65%;
  max-height: 80vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
}

.icon_wrapper {
  display: flex;
  justify-content: flex-end;
}

.content {
  padding: 60px 60px 80px 60px;
  overflow-y: auto;
}

.modal_close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 300;
  color: $neutral4;
}

.modal_close:hover {
  color: $risk5;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.icon {
  margin-right: 12px;
}

.title {
  color: $neutral7;
  font-size: $heading3;
  font-weight: $mediumWeight;
  margin-bottom: 24px;
  line-height: 32px;
}

.risk_status {
  text-decoration: underline;
}

.at_risk {
  @extend .risk_status;
  color: $risk3;
}

.unexpected {
  @extend .risk_status;
  color: $unexpected3;
}

.expected {
  @extend .risk_status;
  color: $success2;
}

.context {
  align-self: flex-start;
  color: $neutral5;
  font-size: $body;
  white-space: pre-wrap;
}

@media (max-width: $mobile-break-point) {
  .modal_close {
    top: 20px;
    right: 20px;
  }
  .content {
    padding: 36px 36px 42px 36px;
  }
}

@media (max-width: 400px) {
  .content {
    padding: 32px 48px 32px 32px;
  }
  .risk_status_inner {
    width: 80%;
  }
  .title {
    font-size: $heading5;
  }
  .context {
    font-size: $bodyMedium;
  }
}

@media (max-width: 340px) {
  .modal_close {
    top: 15px;
    right: 15px;
  }
  .risk_status_inner {
    width: 90%;
  }
}
