@import "../../Styles/theme.scss";

.section_title {
  color: $neutral7;
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
}

.hr {
  border: 0.5px solid #eaeaea;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.no_events {
  color: $neutral5;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
  margin-bottom: 24px;
}
