@import "../../Styles/theme.scss";

.square {
  width: 29px;
  min-width: 29px;
  height: 27px;
  margin-right: 1px;
  background-color: $primary4;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: $small-font-size;
  font-weight: bold;
  padding-top: 2px;
  cursor: default;
  box-sizing: border-box;
}

.square_even {
  @extend .square;
  background-color: $neutral1;
}

.square_odd {
  @extend .square;
  background-color: $neutral2;
}

.square_selected {
  border: 1px solid $secondary3;
}
