@import "../../Styles/theme.scss";

.search_result_user_card_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
  border: 0.5px solid $neutral2;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.search_user_icon {
  display: flex;
  align-items: center;
}

.user_details {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  align-self: center;
}

.userstring {
  font-weight: $mediumWeight;
  color: $neutral7;
}

.clickable_behaviour {
  color: $primary6;
  text-decoration: underline;
  cursor: pointer;
}

.risk_status_info {
  font-weight: $regular;
  font-size: $bodyExtraSmall;
  color: $neutral5;
  line-height: 20px;
}

@media (max-width: 600px) {
  .search_result_user_card_container {
    padding: 12px;
    width: 100%;
    min-width: unset;
    max-width: none;
  }
}

@media (max-width: 350px) {
  .risk_status_info {
    font-size: $bodyExtraSmall;
  }
}
