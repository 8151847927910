@import "../../Styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 40px;
  padding: 40px;

  background-color: rgb(133, 133, 133, 0.1);
  border-radius: 7px;

  h1,
  p {
    color: $primary6;
  }

  h1 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 1.6rem;
  }

  p {
    max-width: 90%;
    line-height: 26px;
    font-size: 1rem;
  }

  svg {
    font-size: 2rem;
  }
}

@media (max-width: $mobile-break-point) {
  .container {
    margin: 20px;
    padding: 20px;

    h1 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    p {
      font-size: 0.9rem;
    }

    svg {
      font-size: 3rem;
    }
  }
}
