@import "../../Styles/theme.scss";

.burgerMenu_icon {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0;
  cursor: pointer;
  div {
    width: 24px;
    height: 2px;
    background-color: $primary6;
    border-radius: 1px;
  }
}
