@import "../../Styles/theme.scss";

.behaviour_summary {
  min-height: 400px;
  max-height: 600px;
}
.week_summary {
  display: flex;
  margin-top: 20px;
  align-items: center;
  line-height: 1;
  font-size: $bodySmall;
}

.week_summary_date {
  font-family: Inter;
  font-style: normal;
  font-weight: $regular;
  font-size: $bodySmall;
  color: $neutral7;
}

.week_summary_line {
  height: 0.5px;
  flex: 1;
  border-bottom: 0.5px solid $neutral3;
  margin: 0 6px;
}

.week_summary_days {
  margin-right: 12px;
}

.riskSquare {
  margin-right: 4px;
}

.behaviour {
  padding: 15px;
}

.behaviour_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.behaviour_title {
  display: flex;
  margin-bottom: 11px;
  border-radius: 4px;
  width: fit-content;
  padding: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  color: $neutral7;
  font-size: $heading4;
}

.behaviour_name {
  margin-left: 14px;
}

.behaviour_chart {
  height: 200px;
  width: 100%;
  margin-left: 8px;
  margin-top: -10px;
}

.behaviour_sentences {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 70px;
  ul {
    flex: 1;
    margin-left: 48px;
    list-style-type: disc;
    color: $neutral7;
  }
}

.small_margin_top {
  // gap between the chart and the sentences on the first page should be smaller
  margin-top: 48px;
}
