@import "../../Styles/theme.scss";

.expected {
  display: flex;
  justify-content: space-between;
}
.expected_number {
  color: $neutral7;
  font-size: $heading3;
  font-weight: $mediumWeight;
}
.expected_expected {
  color: $neutral5;
  font-size: $baseFontSize;
}

.expected_hr {
  border: 0.25px solid $neutral2;
  margin: 6px 0;
  width: 100%;
}
