@import "../../Styles/theme.scss";

.tooltip {
  position: absolute;
  z-index: 100;
  cursor: auto;
  font-size: $bodyExtraSmall;
  display: flex;
  line-height: 25px;
  flex-direction: column;
  color: $neutral7;
  padding: 11px 16px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: 50px;
  left: 70px;
  width: 300px;
  background-color: $white;
}
