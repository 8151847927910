@import "../../Styles/theme.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h2 {
    font-size: $heading-3;
    text-align: center;
    margin-top: 12px;
  }
}

.buttons {
  margin-bottom: 18px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.manual_entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 12px;
}

.manual_entry_message {
  font-weight: 400;
  font-size: 16px;
  color: $risk5;
  margin-bottom: 12px;
}

.qr_hint {
  font-weight: 400;
  font-size: 16px;
  color: $neutral7;
  margin-bottom: 12px;
}

.qrImage {
  width: 100px;
  object-fit: contain;
  align-self: center;
}

.information_link {
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 16px;
  color: $primary6;
  cursor: pointer;
}
