@import "../../Styles/theme.scss";

.page {
  padding: 24px;
  flex: 1;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: $desktop-padding;
  box-sizing: border-box;
}

.content_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 48px;
  max-width: 900px;
}

.profile {
  display: flex;
  flex-direction: column;
}

.profile_image {
  height: 180px;
  width: 180px;
  min-width: 180px;
  padding: 4px;
  h2 {
    font-size: $heading-2;
  }
}

.profile_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 12px;
  h2 {
    margin: 0;
  }
}

.notifications {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  h3 {
    width: 80%;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: $mobile-break-point) {
  .page {
    padding: 13px;
  }
  .content_section {
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .profile {
    width: 100%;
  }
  .profile_image {
    margin: auto;
  }
}
