@import "../../Styles/theme.scss";

// Form container
.preview_report_form_container {
  position: sticky;
  z-index: 500; // so that its clickable and has a higher z-index compared to the background overlay. Loading spinner has a z-index of 600, so it needs to be less than that.
  height: 100%;
  background-color: $white;
  padding: 24px;
  margin-bottom: -4px; // remove gap at the bottom
}

// Containers
.top {
  align-items: baseline;
}
.bottom {
  margin-top: 18px;
  margin-bottom: 18px;
}
.top,
.top_right,
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top_left {
  flex: 0.85;
}
.top_right {
  padding-right: 14px;
}
.bottom_left {
  flex: 1;
  margin-right: 24px;
}
.top_left,
.bottom_left,
.bottom_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.bottom_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 14px;
}

// Title & Description
.preview_title {
  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  font-size: 24px;
  padding-bottom: 16px;
}

.preview_description {
  font-family: Inter;
  font-style: normal;
  font-weight: $regular;
  font-size: $bodySmall;
  color: $neutral5;
}

.loading_spinner {
  margin-left: -15px;
  margin-right: 10px;
}

.copy_report_link,
.download_report,
.save_changes_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-weight: $regular;
  font-size: $bodyExtraSmall;
  cursor: pointer;
}

.disabled {
  color: $neutral7 !important;
  border: 3.2px solid $neutral2 !important;
  background: $neutral2 !important;
  pointer-events: none !important;
}

.copy_report_link,
.download_report {
  min-width: 110px;
  background: $white;
  border: 1px solid $neutral2;
  border-radius: 4px;
  padding: 9px 20px;
  height: 20px;
  flex-direction: row;
  align-items: center;
  color: $primary6;
  font-size: $bodyExtraSmall;
  line-height: 12px;
  svg {
    padding-right: 8px;
  }
}

.copy_report_link {
  margin-left: 14px;
}

.save_changes_button,
.is_saving_changes {
  font-size: $bodySmall;
}

.save_changes_button {
  width: 110px;
  background: $primary3;
  border-radius: 4px;
  padding: 8px 14px;
  color: $neutral7;
}

.is_saving_changes {
  background: #40a9ff50;
}

// Calendar
.desktop_calendar_range {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.desktop_datepicker_group:first-of-type {
  margin-right: 32px;
}
.desktop_datepicker_group {
  flex: 1;
}
.behaviour_container {
  margin-top: 10px;
}
.behaviours_label,
.desktop_datepicker_label {
  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  font-size: $bodySmall;
  line-height: 21px;
}
.select_date {
  margin-top: 8px;
  font-size: $bodyExtraSmall;
  color: $neutral5;
  display: block;
  svg {
    position: relative;
    top: 3px;
    right: -5px;
  }
  input[type="date"] {
    text-align: left; // this works on Chrome, but not on Safari - you have to set it manually (see further down)
    color: $neutral5;
    font-family: Inter;
    font-size: $bodySmall;
    font-style: normal;
    font-weight: $regular;
    letter-spacing: 0px;
    line-height: 21px;

    margin: 0 6px 0 0;
    padding: 5px 14px;
    margin-left: -20px; // to adjust for Calendar Icon SVG which shifts the input box upward (this only applies to the input on Desktop)

    border: 1px solid $neutral2;
    border-radius: 3.61px;
    background-color: $white;

    background-color: $neutral1;
    text-transform: uppercase;
    width: 130px;
  }
  input[type="date"]::-webkit-date-and-time-value {
    // Necessary for Safari
    text-align: left;
  }
  // change the position of the datepicker/calendar icon
  // https://stackoverflow.com/questions/63121577/how-do-i-change-the-position-of-the-datepicker-calendar-icon-in-my-bootstrap-for
  input[type="date"]::-webkit-calendar-picker-indicator {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input::-webkit-datetime-edit {
    position: relative;
    left: 8px;
  }
  input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 8px;
  }
}

// Behaviour options
.no_behaviour_selected {
  font-size: 14px;
  font-weight: $mediumWeight;
  color: $risk3;
}
.behaviour_tickbox {
  margin-right: 6px;
  margin-left: 0px;
  height: 16px;
  width: 16px;
}

.behaviour_option {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  flex-wrap: wrap;
}
.selected_behaviour {
  display: flex;
  flex-direction: row;
  font-size: $bodySmall;
  padding-right: 18px;
  padding-bottom: 6px;
}
.disabled_selected_behaviour {
  @extend .selected_behaviour;
  color: $neutral4;
}
// text area
.text_area_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 14px;
}
.summary_label {
  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  font-size: $bodySmall;
  margin-bottom: 8px;
}
.text_area {
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  padding: 12px;
  background: $neutral1;
  border: 1px solid $neutral4;
  border-radius: 2px;
  font-family: Inter;
  resize: "none";
  color: $neutral7;
}

@media (max-width: 900px) {
  // calendar
  .select_date {
    input[type="date"] {
      width: 120px;
    }
  }
}

@media (max-width: 800px) {
  .preview_report_form_container {
    padding-bottom: 24px;
    margin-bottom: 0px;
  }
  // text area
  .text_area_container {
    padding-left: 12px;
  }
  // calendar
  .select_date {
    margin-top: -20px;
    svg {
      position: relative;
      top: 36px;
      right: -8px;
    }
    input[type="date"] {
      width: 110px;
      margin-left: -2px;
      margin-top: 2px;
    }
    input::-webkit-datetime-edit {
      position: relative;
      left: 10px;
    }
    input::-webkit-datetime-edit-fields-wrapper {
      position: relative;
      left: 10px;
    }
  }
}

@media (max-width: 600px) {
  .preview_report_form_container {
    max-height: 90vh;
    overflow: auto;
  }
  // containers
  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .top_left {
    flex: 1;
  }
  .top_buttons_mobile {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
  }
  .bottom_left {
    margin-right: 0px;
  }
  .bottom {
    margin-top: 12px;
    margin-bottom: 0px;
    padding-bottom: 24px;
  }
  .bottom_buttons {
    padding-bottom: 0px;
    padding-right: 0px;
  }
  // buttons
  .save_changes_button {
    flex: 1;
  }
  .invalid_form {
    cursor: not-allowed;
  }
  .copy_report_link,
  .download_report {
    flex: 1;
    height: 46px;
    font-size: $bodyMedium;
    min-width: unset;
    padding: 9px 8px;
  }
  // text area
  .text_area_container {
    padding-top: 14px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .text_area {
    width: 100%;
    border: 1px solid $neutral2;
    font-size: $bodyMedium;
  }
  // behaviours
  .selected_behaviour {
    font-size: $bodyMedium;
  }
  .behaviours_label,
  .summary_label,
  .desktop_datepicker_label {
    font-size: $bodyMedium;
  }
  // new calendar
  .mobile_date_range {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .mobile_calendar_group {
    flex: 1;
    // Necessary for Safari
    input[type="date"]::-webkit-date-and-time-value {
      text-align: left;
    }
  }
  .mobile_calendar_group:first-of-type {
    margin-right: 14px;
  }
  .mobile_calendar_label {
    padding-top: 12px;
  }
  .mobile_calendar_box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    border-radius: 3.61px;

    margin-left: -12px;
    padding: 5px 0px 5px 12px;

    text-transform: uppercase;

    input[type="date"] {
      text-align: left; // this works on Chrome, but not on Safari - you have to set it manually (see further down)
      color: $neutral5;
      margin-left: 12px;
      height: 36px;
      width: 100%;
      font-size: $body;
      font-family: Inter;
      font-size: $bodyMedium;
      font-style: normal;
      font-weight: $regular;
      letter-spacing: 0px;
      line-height: 21px;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: 400px) {
  .mobile_date_range {
    flex-direction: column;
    display: flex;
  }
  .mobile_calendar_group:first-of-type {
    margin-right: 0px;
    margin-bottom: 12px;
  }
}

@media (max-width: 360px) {
  .copy_report_link,
  .download_report {
    flex: 1;
  }
}
