.chart {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.small {
  width: 60px;
  height: 60px;
}
