@import "../../Styles/theme.scss";

.h1 {
  font-size: 32px !important;
}

.twofa_message {
  font-size: 18px;
  margin-bottom: 48px;
  line-height: 26px;
  color: $neutral6;
}

.error_messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.bottom_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.help_button {
  width: 33%;
}

.input {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  .inputError {
    border: 1px solid $risk5;
  }
}

.input:first-child {
  margin-bottom: 24px;
}

.input_input {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input_label {
  margin-right: 6px;
  width: 100px;
}

.field_check {
  display: flex;
  font-size: $bodySmall;
  align-items: center;
}

.error {
  padding-top: 16px;
  color: $risk5;
  align-self: center;
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  text-align: center;
}

@media (max-width: 600px) {
  .help_button {
    width: 50%;
  }
}

@media (max-width: 280px) {
  .twofa_message {
    padding: 20px;
  }
  h2 {
    font-size: 28px !important;
  }
}
