@import "../../Styles/theme.scss";

.sensor_wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
}

.square {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-right: 12px;
  margin-top: 4px;
}

.details {
  display: flex;
  flex-direction: column;
}

.sensor_name {
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $body;
}

.sensor_label {
  color: $neutral7;
  font-weight: $regular;
  font-size: $bodyMedium;
}

@media (max-width: 600px) {
  .sensor_name {
    font-size: $bodyMedium;
  }
  .sensor_label {
    font-size: $bodySmall;
  }
}
