@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 0;
  padding: 1px;
  p {
    margin: 12px 0;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid $neutral3;
    border-radius: 8px;
    font-size: $bodySmall;
    margin-top: 6px;
  }

  input:focus-visible {
    outline: 1px solid $primary3;
    border-radius: 8px;
    border-color: $primary3;
  }

  .error-input {
    border: 1px solid $risk6;
  }

  label {
    font-size: $bodyMedium;
    color: $neutral7;
  }
}

.input-form {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral3;
  border-radius: 8px;
  font-size: $bodySmall;
  margin-top: 6px;
}

.error-input {
  border: 1px solid $risk6;
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.input {
  margin: 6px 0;
}

.error {
  color: $risk5;
  font-size: $bodySmall;
}
