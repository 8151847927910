@import "../../Styles/theme.scss";

// Page and Report containers
.page {
  align-items: stretch;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  padding: 0;
  max-height: calc(100vh - 65px);
}

.survey {
  display: flex;
  position: absolute;
  top: 24px;
  right: 40px;
  z-index: 200;
  max-width: 220px;
  border-radius: 6px;
  box-shadow: 2px 2px 4px 4px $neutral2;
  background-color: $white;
  flex-direction: column;
  margin: 0px 0px;
  padding: 24px 29px;
  h1 {
    color: $neutral7;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    color: $neutral6;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.report {
  overflow: auto;
  background-color: $neutral2;
  margin: 0px 0px;
  flex: 1;
}

.error_fetching_report {
  height: 100vh;
}
