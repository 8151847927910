@import "../../Styles/theme.scss";

.card {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 18px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px !important;
  font-size: $small-font-size;
  box-shadow: 3px 3px 3px $neutral1;
}

.card_top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.card_right {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  line-height: $reduced-line-height;
}

.card_right_name {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $baseFontSize;
  margin-bottom: 6px;
}

@media (max-width: $mobile-break-point) {
  .card {
    margin: 6px 0;
    width: 100%;
  }
}
