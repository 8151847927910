@import "../../../Styles/theme.scss";

.header_image {
  display: "flex";
  align-items: "center";
  align-content: "center";
  justify-content: "center";
}

.title {
  font-family: 'Inter';
  font-size: $heading3;
  font-weight: $mediumWeight;
  line-height: 31px;
  text-align: left;
  color: $neutral7;
  padding-top: 32px;
  padding-bottom: 32px;
}

.description {
  font-family: 'Inter';
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 22px;
  text-align: left;
  color: $neutral6;
  padding-bottom: 32px;
}

