@import "../../Styles/theme.scss";

.comparison_modal {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.breakdown {
  flex: 1;
}

.breakdown:first-of-type {
  margin-right: 150px;
}

.footnote {
  color: $neutral5;
}

@media (max-width: 1100px) {
  .breakdown:first-of-type {
    margin-right: 40px;
  }
}

@media (max-width: 900px) {
  .breakdown:first-of-type {
    margin-right: 30px;
  }
}
