@import "../../../Styles/theme.scss";

.alt_spinner {
  position: relative;
  width: 50px;
  height: 50px;
}

.alt_spinner_small {
  position: relative;
  width: 16px;
  height: 16px;
}

.alt_spinner_large {
  position: relative;
  width: 56px;
  height: 56px;
}

.logo {
  position: absolute;
  top: 40%;
  left: 38%;
}

.text {
  position: absolute;
  bottom: 0;
  font-size: $bodyExtraSmall;
  left: 12px;
  color: $neutral6;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  margin-left: -18px;
  border-radius: 50%;
  border: 3px solid $white;
  border-top-color: $primary3;
  animation: spinner 0.8s linear infinite;
}

.spinner_small:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  border-radius: 50%;
  border: 2px solid $white;
  border-top-color: $primary3;
  animation: spinner 0.8s linear infinite;
}

.spinner_large:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  border-radius: 50%;
  border: 6px solid $neutral2;
  border-top-color: $primary3;
  animation: spinner 0.8s linear infinite;
}
