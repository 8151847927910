@import "../../Styles/theme.scss";

.container {
  display: flex;
}

@media (max-width: $mobile-break-point) {
  .container {
    flex-direction: column;
  }
}
