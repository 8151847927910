@import "../../Styles/theme.scss";

.dashboard_search_container {
  display: flex;
  flex: 1;
  position: relative;
  padding: 5px 18px 5px 42px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $neutral2;
  padding-right: 32px;
  align-items: center;
  align-self: stretch;
}

.clickable_dashboard_search {
  display: flex;
  width: 100%;
  input {
    width: 100%;
    border: none;
    background-color: $neutral2;
    color: #9c9c9c;
    font-weight: $mediumWeight;
    font-size: $body;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
    color: #9c9c9c;
    font-weight: $mediumWeight;
  }
}

.focused_dashboard_search_container {
  width: 50%;
}

.dashboard_search_icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 24px;
  height: 24px;
  color: $neutral4;
}

.dashboard_close_icon {
  position: absolute;
  right: 18px;
  width: 24px;
  height: 24px;
  color: $neutral4;
  cursor: pointer;
}
