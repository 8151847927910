@import "../../../Styles/theme.scss";

.square {
  cursor: pointer;
  height: 53px;
  width: 53px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

// base variations
.connected {
  background-color: $expected1;
  border: 1px solid $expected2;
}
.not_connected {
  background-color: $neutral1;
  border: 1px solid $neutral2;
}
.not_selected {
  font-weight: $regular;
  color: $neutral6;
}
.selected {
  font-weight: $mediumWeight;
  color: $neutral7;
}

.underline {
  position: absolute;
  bottom: -12px;
  left: 4;
  width: 80%;
  border-bottom: 3px solid $primary3;
  border-radius: 5px;
}
.no_underline {
  border-bottom: none;
}

// Combinations of the above 4 variations
.connected_selected  {
  @extend .square;
  @extend .connected;
  @extend .selected;
}
.connected_not_selected {
  @extend .square;
  @extend .connected;
  @extend .not_selected;
}
.not_connected_selected {
  @extend .square;
  @extend .not_connected;
  @extend .selected;
}
.not_connected_not_selected {
  @extend .square;
  @extend .not_connected;
  @extend .not_selected;
}

@media (max-width: 800px) { 
  .square { 
    min-height: auto;
    min-width: auto;
    max-height: auto;
    max-width: auto;
  }
}