@import "../../Styles/theme.scss";

.content {
  display: flex;
  width: auto;
  flex-direction: column;
  background-color: $white;
  padding: 25px 29px;
  align-items: flex-start;
  margin: 15px;
  border-radius: 4px;
}

@media (max-width: $mobile_break_point) {
  .content {
    padding: 43px 22px 22px 22px;
    margin: 0;
  }
}
