@import "../../Styles/theme.scss";

.table {
  display: flex;
  border: 1px solid $neutral2;
  border-radius: 4px;
  flex-direction: column;
  color: $neutral6;
  line-height: 18px;
}

.table_header {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $neutral1;
  div {
    padding: 18px 0 18px 27px;
    font-size: $bodySmall;
    font-weight: $mediumWeight;
    width: 140px;
    box-sizing: border-box;
  }
}

.no_content_message {
  font-size: $bodySmall;
  font-weight: 400;
  color: $neutral7;
}
