@import "../../Styles/theme.scss";

// Days of the week component
.one_week {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 12px;
}
.days_of_the_week {
  @extend .one_week;
}
// Day of the week component
.day_of_the_week {
  display: flex;
  justify-content: center;
  color: $neutral5;
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  width: 100%;
}
.day_of_the_week_desktop {
  @extend .day_of_the_week;
  min-width: 125px;
  max-width: 125px;
  min-height: 30px;
  max-height: 30px;
  margin-right: 12px;
  padding: 8px;
  padding-left: 18px;
  box-sizing: border-box;
}
.day_of_the_week_desktop:last-child {
  margin-right: 0px;
}
.day_of_the_week_mobile {
  @extend .day_of_the_week;
  min-width: unset;
  max-width: unset;
}

@media (max-width: 1440px) {
  .day_of_the_week_desktop {
    @extend .day_of_the_week;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 90px;
    max-height: 90px;
  }
}
@media (max-width: 1184px) {
  .day_of_the_week_desktop {
    display: flex;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}

@media (min-width: 1620px) {
  .day_of_the_week_desktop {
    display: flex;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}
