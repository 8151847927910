@import "../../Styles/theme.scss";

.flex_start {
  justify-content: flex-start !important;
}
.space_evenly {
  justify-content: space-evenly !important;
}
.page {
  padding: $pdf-padding;
  padding-bottom: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    // "overview" and "summary" headings
    color: $neutral7 !important;
    font-weight: $mediumWeight !important;
    font-size: $heading4 !important;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  img {
    object-fit: contain;
  }
}

.header_right {
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  font-size: $bodySmall; // $small-font-size; (variables 0.8rem)
  div {
    text-align: end;
  }
}

.service_user {
  background-color: $white;
  padding: $pdf-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service_user_left {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  column-gap: 18px;
  row-gap: 3px;
  color: $neutral7;
  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  h2 {
    margin: 0;
  }
  .user_detail {
    font-weight: $regular;
  }
}

.service_user_left_image {
  grid-row-start: 1;
  grid-row-end: 5;
}

.service_user_left_name {
  grid-column-start: 2;
  grid-column-end: 4;

  font-family: Inter;
  font-style: normal;
  font-size: $heading4;
  font-weight: $mediumWeight;
  color: $neutral7;
  margin-bottom: 2px;
}

.service_user_date_range {
  font-size: $heading4;
  color: $neutral7;
  font-weight: $mediumWeight;
}

.summary {
  h2 {
    margin-top: 30px;
    margin-bottom: 12px;
  }
}

.summary_text {
  height: 180px;
  width: 100%;
  overflow-wrap: break-word;
  color: $neutral7;
}

.summary_text_author {
  margin-top: 12px;
  color: $primary6;
  font-weight: $light;
}

.overview {
  margin-bottom: 24px;
  h2 {
    margin-top: 12px;
    margin-bottom: 14px;
  }
}

.keys {
  display: flex;
  margin-top: 24px;
}
.keys_right {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.key {
  display: flex;
}
.key_item {
  margin-left: 20px;
  font-size: $bodySmall;
  display: flex;
  align-items: center;
}

.key_name {
  margin-top: 3px;
}

.riskSquare {
  margin-right: 6px;
}

.pageNumber {
  position: absolute;
  font-size: $bodySmall;
  color: $neutral4;
  bottom: 20px;
  right: 6%;
}

.line_break {
  border: 1px solid $neutral2;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 80%;
}
