@import "../../Styles/theme.scss";

.container {
  position: relative;
  label {
    font-size: $bodySmall;
    color: $neutral7;
    font-weight: $mediumWeight;
  }
}

.container_range {
  display: flex;
}

.container_range_input {
  padding: 8px 17px;
  display: flex;
  align-items: center;
  min-width: 210px;
  box-sizing: border-box;
  border: 1px solid $neutral3;
  border-radius: 8px;
  background-color: $white;
  z-index: 300;
  position: relative;
  p {
    margin: 0 0 0 8px;
    font-size: $bodySmall;
    color: $neutral4;
    font-weight: $mediumWeight;
  }
}

.container_range_input_selected {
  @extend .container_range_input;
  border: 1px solid $primary3;
  p {
    color: $neutral6;
  }
}

.container_range_input_required {
  @extend .container_range_input;
  border: 1px solid $risk3;
}

.container_picker {
  z-index: 700;
  position: absolute;
  top: 73px;
  left: -200px;
  border-radius: 8px;
  padding: 16px;
  background-color: $white;
  box-shadow: 0px 8px 32px 0px rgba(19, 10, 46, 0.07);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.arrow {
  margin-top: 34px;
  margin-left: 4px;
  margin-right: 4px;
}
