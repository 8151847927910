@import "../../Styles/theme.scss";

.container {
  display: flex;
}

.item {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: $heading2;
    font-weight: $mediumWeight;
    margin-bottom: 0;
  }
  div {
    font-size: $bodySmall;
  }
}
