@import "../../../Styles/theme.scss";

.reportType {
  margin-top: 48px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  h2 {
    font-weight: $mediumWeight;
    font-size: $heading4;
    margin-top: 0;
  }
}

.reportType_row {
  display: flex;
}

.newReportContainer {
  display: flex;
  flex-direction: column;
}

.reportTypeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}

.reportTypeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border: 1px solid $neutral1;
  border-radius: 8px;
  width: 135px;
  height: 135px;
  box-sizing: border-box;
  border: 1px solid $neutral3;
  color: $neutral5;
}

.focusedReportTypeButton {
  @extend .reportTypeButton;
  background-color: $primary1;
  border: 1px solid $primary3;
  color: $neutral7;
}

.available {
  cursor: pointer;
}
.unavailable {
  cursor: not-allowed;
  background-color: $neutral2;
  border: 1px solid $neutral2;
  color: $neutral4;
}

.reportTypeButtonTitle {
  margin-top: 8px;
  text-align: center;
  font-weight: $mediumWeight;
  font-size: $bodySmall;
  line-height: 21px;
}

.reportType_comingSoon {
  font-size: $bodyExtraSmall;
}

.whatsIncludedContainer {
  display: flex;
  flex-direction: column;
  min-width: 40%;
  margin-left: 10px;
  border: 1px solid $neutral3;
  border-radius: 8px;
  padding: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.horizontalBar {
  width: 100%;
  height: 1px;
  background-color: $neutral2;
  margin-bottom: 17px;
}

.subheading {
  font-size: $bodySmall;
  font-weight: 400;
  color: $neutral5;
  margin-bottom: 5px;
}

.section {
  margin-top: 5px;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.list {
  list-style: decimal inside;
  background-color: $neutral1;
  padding: 10px;
  border-radius: 8px;
}

.item {
  font-size: 12px;
  font-weight: 400;
}

.options {
  display: grid;
  margin-top: 12px;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
}

.option {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  input {
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    margin-right: 8px;
    accent-color: $primary5;
  }
}
