@import "../../Styles/theme.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  background-color: $white;
  padding: $desktop-padding;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid $neutral2;
}

@media (max-width: $mobile-break-point) {
  .card {
    background-color: $white;
    padding: 0px;
    border-radius: none;
    box-shadow: none;
    border: none;
  }
}
