@import "../Styles/theme.scss";

.form {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 12px 0;
  }

  input,
  select,
  textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid $neutral3;
    border-radius: 6px;
    font-size: $baseFontSize;
  }

  textarea {
    width: 100%;
    height: 70%;
    min-height: 100px;
  }
}

.input {
  margin: 6px 0;
}

.onboardingButtons {
  display: flex;
  justify-content: space-between;
}

.submit {
  align-self: flex-end;
  margin-top: 6px !important;
}

.error {
  color: red;
}

@media (min-width: $mobile-break-point) {
  .form {
    flex-grow: 0;
  }
}
