@import "../../Styles/theme.scss";

.temporary_donut {
  margin-right: 20px;
}

.movement_breakdown_container {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 12px;
}

.expected_events {
  display: flex;
  justify-content: flex-start;
  color: $neutral5;
  padding-bottom: 14px;
}

.underline {
  text-decoration: underline;
  color: $neutral7;
  font-weight: $mediumWeight;
}

.actual_events {
  display: flex;
  flex-direction: row;
  align-items: center;
}

hr {
  width: 100%;
  border-top: 0.5px solid $neutral2;
  margin-top: 13px;
}

.last_period,
.dates {
  font-size: $body;
  color: $neutral7;
}

.last_period {
  font-weight: $mediumWeight;
}

.dates {
  font-weight: $regular;
}
.movement_graph {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 150px;
  margin-right: 24px;
}

.stats {
  display: flex;
  flex-direction: column;
}

.main_stat {
  line-height: 1.1;
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $heading1;
  margin-bottom: 10px;
}

.label {
  margin-left: 3px;
  color: $neutral5;
  font-size: $body;
}

.percentChange {
  margin-left: 3px;
  margin-top: 4px;
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
}

.no_color {
  @extend .percentChange;
  color: $white;
}

.color {
  @extend .percentChange;
  color: $primary3;
}

.bottom {
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (max-width: 1140px) {
  .movement_graph {
    display: none;
  }
  .top {
    flex-direction: column;
  }
  .last_period {
    font-size: $heading4;
  }
}

@media (max-width: 800px) {
  .temporary_donut {
    display: none;
  }
}

@media (max-width: 600px) {
  .last_period {
    font-size: $body;
  }
  .dates {
    font-size: $bodySmall;
  }
  .main_stat {
    font-size: $heading4;
  }
  .label {
    font-size: $bodyMedium;
    margin-left: 0px;
  }
  .percentChange {
    margin-left: 0px;
  }
}
