@import "../../../Styles/theme.scss";

.timeframe {
  align-self: center;
  flex: 1;
  h2 {
    margin-top: 64px;
  }
}

.required {
  color: $risk6;
}
