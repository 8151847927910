@import "../../Styles/theme.scss";

.container {
  position: relative;
  display: flex;
  height: 34px;
  width: 200px;
  border-radius: 17px;
  box-sizing: border-box;
  background-color: $white;
  padding-right: 32px;
}

.container_clickable {
  padding-left: 32px;
  display: flex;
  width: 100%;
  input {
    border-radius: 17px;
    width: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }
}

.container_focused {
  width: 50%;
}

.searchIcon {
  position: absolute;
  top: 5px;
  left: 8px;
  width: 24px;
  height: 24px;
  color: $neutral4;
}

.closeIcon {
  position: absolute;
  top: 5px;
  right: 8px;
  width: 24px;
  height: 24px;
  color: $neutral4;
}

@media (max-width: $mobile-break-point) {
  .container {
    width: 90%;
  }
  .container_focused {
    width: 90%;
  }
}
