@import "../../Styles/theme.scss";

.behaviour_details_container {
  flex-direction: column;
  padding-top: 24px;
}

.behaviour_details_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: $mediumWeight;
  font-size: $heading5;
  text-align: left;
  color: $neutral7;
  margin-bottom: 8px;
}

.detail_date {
  font-weight: $mediumWeight;
  font-size: $bodySmall;
  color: $neutral5;
}

.behaviour_details_body {
  min-height: 80px;
  max-height: 270px;
  overflow-y: auto;
  padding-right: 8px;
  padding-bottom: 16px;
}

.behaviour_detail_item {
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  border-top: 1px $neutral2 solid;
  text-align: left;
}

.behaviour_detail_item:first-child {
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  border-top: none;
  text-align: left;
}

.sentence_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.behaviour_details_time {
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  color: $neutral7;
}

.behaviour_details_sentence {
  font-weight: $regular;
  font-size: $bodyMedium;
  color: $neutral7;
}

.behaviour_duration {
  font-weight: $regular;
  font-size: $bodySmall;
  color: #555555;
}
