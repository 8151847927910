@import "../../Styles/theme.scss";

.container {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  margin-bottom: 13px;
  font-size: 22px;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $primary6;
  height: 14px;
  width: 14px;
  border: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 1px solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  border: 1px $primary6;
  top: 2px;
  width: 2px;
  height: 5px;
  border: solid white;
  border-width: 0 1.8px 1.8px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
