@import "../../Styles/theme.scss";

.dashboard_filter_and_search_container {
  padding-bottom: 47px;
}

.search_input_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 14px;
}

.search_tabs_and_buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: $mobile-break-point) {
  .search_input_container {
    flex-direction: column-reverse;
  }
  .dashboard_filter_and_search_container {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 4px;
  }
}
