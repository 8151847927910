@import "../../Styles/theme.scss";

.notes {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  h2 {
    font-size: $baseFontSize;
    font-weight: $mediumWeight;
    margin: 12px 0;
  }
  h3 {
    font-size: $bodySmall;
    font-weight: $regular;
    margin: 12px 0;
    text-transform: none;
  }
  span {
    font-size: $bodySmall;
  }
}

.notes_previous {
  flex: 1;
  overflow-y: auto;
  max-height: 35vh;
}

.note {
  margin-bottom: 20px;
}

.note_value {
  background-color: $neutral1;
  padding: 16px;
  margin-bottom: 10px;
  font-size: $bodySmall;
  color: $neutral7;
}

.note_data {
  display: flex;
  justify-content: space-between;
}

.note_data_right {
  display: flex;
  align-items: center;
  div {
    font-size: $bodySmall;
    color: $neutral4;
  }
}

.blue {
  color: $primary3;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 46px;
  button {
    margin-left: 16px;
  }
}

.noNotes {
  color: $neutral5;
}
