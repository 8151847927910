@import "../../Styles/theme.scss";

.hint {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $neutral2;
  border-radius: 8px;
}
.hint_top {
  display: flex;
  justify-content: space-between;
}

.hint_top_icon {
  display: flex;
}

.hint_description {
  margin-top: 16px;
  font-family: 'Inter';
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 21px;
  text-align: left;
  color: $neutral7;
}
