@import "../../Styles/theme.scss";

.notifications_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  padding: 35px 37px;
  font-family: 'Inter';
}
.outer_container {
  padding: 0px 0px 44px 0px;
}
.subheading_medium {
  color: $neutral7;
  font-size: $subHeading;
  font-weight: $mediumWeight;
  line-height: 20px;
  margin-bottom: 10px;
  word-wrap: break-word;
}
.notification_explanation {
  color: $neutral6;
  font-size: $bodySmall;
  font-weight: $regular;
  line-height: 16px;
  word-wrap: break-word;
}
.inner_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.time_selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  input {
    color: $neutral7;
    font-size: 15px;
    font-family: 'Inter';
    font-weight: $mediumWeight;
    line-height: 22.99px;
  }
}
.time {
  margin-right: 20px
}
.top_section, .bottom_section {
  display: flex;
  flex-direction: row;
}
.top_section {
  margin-bottom: 26px;
  padding-bottom: 26px;
  border-bottom: $neutral2 solid 1px;
  word-wrap: break-word;
};

.column_1 {
  width: 385px;
}
.column_2 {
  min-width: 150px;
  width: 183px;
}
.column_3, .column_4 {
  min-width: 150px;
  width: 183px;
}
.column_2, .column_3, .column_4 {
  margin-left: 115px;
}

.bottom_section {
  .column_1, .column_2, .column_3, .column_4 {
    color: $neutral6;
    font-size: $bodyMedium;
    font-weight: $regular;
    line-height: 20px;
    word-wrap: break-word;
  }
  .title {
    color: $neutral7;
    font-size: $body;
    line-height: 22px;
  }
  .description{
    margin-top: 10px;
    margin-bottom: 30px;
    word-wrap: break-word;
  }
}

.notifications_error_message {
  color: $risk6;
  margin-top: 14px;
}

@media (max-width: 1217px) {
  .column_2, .column_3, .column_4 {
    margin-left: 20px;
  }
}

@media (max-width: 924px) {
  .column_2, .column_3, .column_4 {
    margin-left: 10px;
  }
}

@media (max-width: 894px) {
  .column_2, .column_3, .column_4 {
    margin-left: 0px;
  }
}

@media (max-width: 863px) {
  .column_1 {
    width: 200px;
  }
  .column_2, .column_3, .column_4 {
    width: 130px;
  }
}

.mobile_title {
  color: $neutral7;
  font-weight: $regular;
  font-size: $body;
  border-bottom: $neutral2 1px solid;
  margin-bottom: 14px;
}
.mobile_description {
  margin-bottom: 14px;
}


.mobile_time_selector {
  @extend .time_selector;
  margin-bottom: 16px;
}

.mobile_time {
  margin-right: 12px;
}

.mobile_notification_delivery {
  margin-bottom: 16px;
}

.mobile_details {
  font-size: $bodyMedium;
  color: $neutral6;
  margin-bottom: 12px;
}

.mobile_toggle {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 310px) {
  .mobile_time {
    margin-right: 6px;
  }
}
