@import "../../Styles/theme.scss";

.behaviour_days_risk_sorting_container {
  flex-direction: column;
  text-align: left;
}

.risk_container {
  padding-top: 14px;
  padding-bottom: 12px;
}

.risk_category_title {
  color: $neutral7;
  font-size: $subHeading;
  font-weight: $mediumWeight;
  padding-bottom: 4px;
}

.risk_category_caption {
  color: $neutral5;
  font-weight: $medium;
  font-size: $bodySmall;
  padding-bottom: 8px;
}

.risk_dates {
  display: flex;
  justify-content: space-between;
}
