
.password_checklist_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 24px;
  margin-bottom: 24px;
}


.password_container {
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 5px;
  }
}
