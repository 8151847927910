@import "../../Styles/theme.scss";

.appliedFilters {
  display: flex;
  flex-direction: column;
  font-size: $small-font-size;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.appliedFilters_title {
  font-weight: bold;
}

.appliedFilters_filters {
  display: flex;
  flex-wrap: wrap;
}
