@import "../../../Styles/theme.scss";

.chart {
  border: 1px solid $neutral3;
  border-radius: 7px;
  max-width: 440px;
  box-sizing: border-box;
  position: relative;
  background-color: $white;
}

.button {
  background-color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
