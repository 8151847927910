@import "../../Styles/theme.scss";

.answers_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.answers_header {
  padding: 0px 0px 36px 0px;
  h2 {
    color: $neutral7;
    margin: 0px;
  }
}

.answers_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.answer {
  color: $neutral7;
  font-size: $body;
  font-weight: $regular;
  padding-bottom: 24px;
}
