@import "../../Styles/theme.scss";

.image {
  width: 140px;
  min-width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
}

.border {
  background-color: white;
  border: 2px solid $neutral4;
  padding: 6px;
}

.image_avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.image_avatar_initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: $neutral7 !important;
}

.initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: $medium;
  font-size: $bodyExtraSmall;
}

.extra_small {
  width: 36px;
  height: 36px;
  min-width: 36px;
}

.small {
  width: 48px;
  height: 48px;
  padding: 3px;
  min-width: 46px;
}

.small_medium {
  width: 57px;
  height: 57px;
  padding: 3px;
  min-width: 54px;
}

.medium {
  height: 100px;
  width: 100px;
  min-width: 100px;
  padding: 3px;
}
