@import "../../Styles/theme.scss";

.dashboard_search {
  display: flex;
  flex-direction: column;
  margin-right: 58px;
  flex: 1;
  width: 100%;
}
.search_title {
  font-weight: $mediumWeight;
  font-size: $heading4;
  color: $neutral7;
}

@media (max-width: 1000px) {
  .dashboard_search {
    margin-right: 0px;
  }
}

@media (max-width: $mobile_break_point) {
  .search_title {
    display: none;
  }
}
