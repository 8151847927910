@import "../../Styles/theme.scss";

.toggle_box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle_text {
  margin-left: 17px;
}

.toggle_container {
  width: 50px;
  height: 25px;
  background-color: $neutral2;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
}

.slider {
  width: 21px; 
  height: 21px; 
  background-color: $white;
  position: absolute;
  top: 1px;
  left: 2.5px; 
  border-radius: 50%; 
  border: $neutral3 1px solid;
  transition: transform 0.3s ease;
}

.toggled {
  transform: translateX(22px);
  background-color: $white;
}

.toggled_background {
  background-color: $primary3;
}
