@import "../../Styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-right: 12px;
  h2 {
    margin: 0 0 16px 0px;
    font-size: $subHeading;
  }
}

.inner_container {
  overflow-y: auto;
  overflow-x: hidden;
}

.toggle {
  display: flex;
  flex-direction: row;
  background-color: $neutral2;
  width: 264px;
  border-radius: 6px;
  padding: 3px;
}

.toggle_option {
  background-color: $neutral2;
  border-radius: 6px;
  flex: 1;
  padding: 6px 0px;
  border: none;
}

.toggle_option_focused {
  @extend .toggle_option;
  background-color: $white;
}

.toggle_events {
  margin-bottom: 16px;
}

.no_data_container {
  @extend .container;
  overflow-y: unset;
  overflow-x: unset;
}

.noEvents {
  color: $neutral5;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
  margin-bottom: 24px;
}

@media (max-width: $mobile-break-point) {
  .container {
    position: relative;
    padding-right: 0;
  }
}
