@import "../../Styles/theme.scss";

.tooltip {
  position: fixed;
  padding-bottom: 6px;
  width: 158px;
  border-radius: 3px;
  z-index: 400;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  background-color: $white;
  font-size: 10px;
}

.tooltip_top {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid $neutral2;
}

.tooltip_top_title {
  color: $neutral5;
}

.tooltip_top_times {
  color: $secondary3;
}

.tooltip_time {
  background-color: $neutral1;
  color: $primary6;
  padding: 8px;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}

.tooltip_time_even {
  background-color: $neutral1;
}

.tooltip_time_right {
  color: $neutral5;
  font-size: 10px;
}
