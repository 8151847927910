@import "../../Styles/theme.scss";

.behaviour {
  display: flex;
  margin-bottom: 2px;
}

.label {
  margin-right: 1.2px;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 4px;
  box-sizing: border-box;
  color: $neutral4;
}

.label_behaviour {
  @extend .label;
  text-align: start;
  margin-right: 6px;
  min-width: 160px;
  color: $neutral7;
  font-weight: $regular;
}

.riskSquare {
  margin-right: 4px;
  border-radius: 0px !important;
  width: 22px !important ;
  height: 22px !important ;
}

.days_of_the_week {
  margin-left: 163px;
  margin-bottom: 4px;
  display: flex;
  font-size: 15px;
}
