@import "../../Styles/theme.scss";

.notifications_container {
  // these styles ensure that the notifications container is always the same width
  // it will not grow to fill the space, only the search container will grow
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 332px;
}

.notifications_header_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
}

.notifications_title_text {
  color: $neutral7;
  font-size: $heading4;
  font-weight: $mediumWeight;
}

.view_all_text {
  color: $neutral5;
  font-size: $bodyExtraSmall;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.today_title_text {
  color: $neutral5;
  font-size: $bodyExtraSmall;
  line-height: 16px;
  font-weight: $mediumWeight;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1000px) {
  .notifications_container {
    display: none;
  }
}
