@import "../../Styles/theme.scss";

.how_it_works_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.how_it_works_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  padding: 0px;
  h2 {
    margin: 0px;
  }
}

.modal_close:hover {
  color: $risk5;
}

.how_it_works_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 48px;
}

.questions,
.answers {
  flex: 1;
}

.questions {
  max-width: 330px;
  margin-right: 40px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: $tablet-break-point) {
  .how_it_works_content {
    flex-direction: column;
    margin-top: 30px;
  }
  .questions {
    max-width: unset;
    margin-right: unset;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    margin-bottom: 40px;
  }
}
