@import "../../Styles/theme.scss";

.outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  h1 {
    text-align: left;
    font-size: 36px;
    margin-bottom: 24px;
    line-height: 36px;
    font-weight: $mediumWeight;
  }

  img {
    margin-bottom: 48px;
  }
}

.inner_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 310px;

  p {
    text-align: left;
  }
}
