@import "../../Styles/theme.scss";

// Event component
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 0.5px solid #eaeaea;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 4.72727px;
  padding: 18px 21px;
  box-sizing: border-box;
}
.bigger_container {
  @extend .container;
  height: 100px;
}
.normal_container {
  @extend .container;
  height: 80px;
}
.icon {
  margin-right: 16px;
}
.info {
  display: flex;
  flex-direction: column;
}
.name {
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $bodySmall;
}
.time {
  color: $neutral7;
  font-size: $bodySmall;
}

@media (max-width: $tablet-break-point) {
  .container {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: $mobile-break-point) {
  .container {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
}
