@import "../../Styles/theme.scss";

.dashboard_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 5;
}

.new_user_button {
  margin-left: 6px;
  background-color: $primary3;
  border: 3px solid $primary3;
  color: $neutral7;
  font-weight: $regular;
  padding: 5px 6px;
  &:hover {
    background-color: $primary4;
    border: 3px solid $primary4;
  }
}

@media (max-width: $mobile-break-point) {
  .dashboard_buttons {
    display: none;
  }
}
