@import "../../../Styles/theme.scss";

.main_image {
  width: 100%;
  height: 100%;
}

.title {
  font-family: 'Inter';
  font-size: $heading3;
  font-weight: $mediumWeight;
  line-height: 31px;
  text-align: left;
  color: $neutral7;
  padding-top: 32px;
  padding-bottom: 32px;
}

.description {
  font-family: 'Inter';
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 22px;
  text-align: left;
  color: $neutral6;
  padding-bottom: 32px;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  cursor: pointer;
  padding: 16px;
  border: 0.5px solid $neutral3;
  border-radius: 8px;
  margin-bottom: 24px;
  min-height: 80px;
  max-height: auto;
  img {
    min-width: 60px;
    max-width: 80px;
    max-height: 60px;
    object-fit: contain;
    width: 100%;
    margin-right: 16px;
  }
}

.selected {
  @extend .option;
  border: 2px solid $primary3;
}

.image_container {
  max-width: 120px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.option_title {
  font-family: 'Inter';
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
  line-height: 14px;
  text-align: left;
  color: $neutral7;
  padding-bottom: 12px;
}

.option_body {
  font-family: 'Inter';
  font-size: $bodyMedium;;
  font-weight: $regular;
  line-height: 22px;
  text-align: left;
  color: $neutral6;
}