@import "../../Styles/theme.scss";

.page_title {
  font-size: $heading2;
  font-weight: $mediumWeight;
  margin-bottom: 8px;
}

.user_info {
  color: $neutral5;
  font-size: $headerBody;
}

.dashboard_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 34px;
}

.component_just_released {
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1194px) {
  .dashboard_header_container {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
  .component_just_released {
    margin-left: 0;
    margin-top: 20px;
  }
  .page_title {
    margin-bottom: 16px;
  }
}
