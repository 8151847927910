.square {
  width: 25px;
  height: 25px;
  border-radius: 2px;
}

.square_small {
  width: 15px;
  height: 15px;
  border-radius: 2px;
}
