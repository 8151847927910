@import "../../Styles/theme.scss";

.signal {
  display: flex;
  font-size: $bodyExtraSmall;
  font-weight: $regular;
  color: $neutral7;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 16px;
}

.connected {
  @extend .signal;
  background-color: $expected1;
  justify-content: space-between;
}
.connected_text {
  padding-left: 4px;
}
.disconnected {
  @extend .signal;
  background-color: $neutral2;
}
