@import "../../Styles/theme.scss";

.layout {
  display: flex;
  button {
    color: $neutral4;
    border: 2px solid $neutral4;
    border-radius: 50%;
    padding: 8px;
    margin-left: 12px;
    box-sizing: border-box;
  }
}

.layout_active {
  button {
    color: $neutral7;
    border: 2px solid $neutral7;
  }
}
