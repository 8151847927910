@import "../../Styles/theme.scss";

.event_log_container {
  background-color: $white;
  padding: 24px;
  border-radius: 4px;
  color: $neutral7;
  h2 {
    margin: 0;
    font-size: $baseFontSize;
    font-weight: 500;
  }
  p {
    font-size: $small-font-size;
    margin-top: 6px;
  }
}

.event_log_log {
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.event_log_rows_container {
  overflow-x: scroll;
  padding-bottom: 14px;
}

.sensor_labels {
  display: flex;
  margin-bottom: 1px;
  flex-direction: column;
  color: $neutral7;
}

.events {
  display: flex;
  flex-direction: row;
  margin-bottom: 1px;
}

.label {
  margin-right: 1px;
  width: 29px;
  min-width: 29px;
  height: 27px;
  text-align: center;
  padding-top: 4px;
  box-sizing: border-box;
  font-size: $small-font-size;
}

.sensor_name {
  @extend .label;
  text-align: end;
  margin-right: 6px;
  width: 110px;
  min-width: 110px;
  font-size: $small-font-size;
}

@media (max-width: $mobile-break-point) {
  .event_log_container {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 24px;
  }
}
