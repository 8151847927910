@import "../../../Styles/theme.scss";

.lds_spinner {
  color: $neutral4;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds_spinner div {
  transform-origin: 12px 12px;
  animation: lds_spinner 0.8s linear infinite;
}
.lds_spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 16px;
  left: 11px;
  width: 2px;
  height: 5px;
  border-radius: 20%;
  background: $neutral4;
}
.white div:after {
  background: $white;
}
.lds_spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.8s;
}
.lds_spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.7s;
}
.lds_spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.6s;
}
.lds_spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.5s;
}
.lds_spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.4s;
}
.lds_spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.3s;
}
.lds_spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds_spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.1s;
}

@keyframes lds_spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
