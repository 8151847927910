@import "../../../Styles/theme.scss";

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  h1 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  p {
    color: $neutral6;
    margin-bottom: 32px;
    max-width: 360px;
  }
}

.backButton {
  &:hover {
    background-color: $primary1;
  }
  &:active {
    border: 1px solid $primary3;
    background-color: $primary3;
    color: $neutral7;
  }
}
