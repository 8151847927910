@import "../../Styles/theme.scss";

.behaviour {
  width: 100%;
  display: flex;
  color: $neutral7;
}

.behaviour_context {
  flex: 1;
  overflow-x: auto;
  margin-right: 48px;
}

.behaviour_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.behaviour_chart {
  flex: 1;
  height: 400px;
}

.chartSummary {
  /* If you change the height by X amount, then you will also need to adjust the values in two other places (by the same amount) inside of the VictoryFlightPathChart. 
     1) VictoryChart height={showingTooltip ? 400 : 300} -> change the RIGHT-hand value by the same amount
     2) VictoryChart padding - "top: showingTooltip ? 110 : 10" --> change the LEFT-hand value by the same amount
  */
  height: 100px;
}

@media (max-width: 900px) {
  .behaviour_chart {
    width: 100%;
    min-height: 200px;
    flex: none;
  }
  .behaviour {
    flex-direction: column;
  }
}
