@import "../../Styles/theme.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pdf_container {
  padding-left: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: $neutral7;
}

.summary_left_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.number {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}

.number_units {
  font-size: $bodyExtraSmall;
  margin-right: 2px;
}

.number_celcius {
  font-size: 18px;
  font-weight: $bold;
  align-self: flex-start;
}

.summary_item_container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.number_value {
  font-weight: $mediumWeight;
  font-size: 42px;
  line-height: 1;
  margin-bottom: 3px;
}
.number_value_small {
  font-size: 36px;
}
.number_value_xsmall {
  font-size: 24px;
}
.summary_label {
  font-weight: $regular;
  font-size: $bodySmall;
  line-height: 16px;
}

.summary_label_small {
  font-size: $bodyExtraSmall;
}

@media (max-width: 1020px) {
  .number_value {
    font-size: 38px;
  }
  .summary_item_container {
    margin-right: 16px;
  }
}

@media (max-width: 932px) {
  .number_value {
    font-size: 36px;
  }
  .summary_item_container {
    margin-right: 14px;
  }
}

@media (max-width: 850px) {
  .summary_item_container {
    margin-right: 20px;
  }
}

@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
  .summary_left_container {
    padding-top: 16px;
    justify-content: flex-start;
  }
  .number_value {
    font-size: 32px;
  }
  .summary_label {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .number_value {
    font-size: 32px;
  }
}

@media (max-width: 350px) {
  .number_value {
    font-size: 28px;
  }
  .summary_label {
    font-size: 12px;
  }
}
