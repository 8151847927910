@import "../../Styles/theme.scss";

.policy {
  display: flex;
  flex-direction: column;
  p {
    text-align: start;
  }
  a {
    color: $primary6;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  h3 {
    text-transform: none;
    font-size: $bodyMedium;
    line-height: 50px;
  }
  table {
    border: 1px solid grey;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid grey;
      padding: 8px;
      text-align: left;
    }
  }
}

.contentsList {
  list-style: inside decimal;
  margin-left: 24px;
  margin-bottom: 24px;
  li {
    font-size: $bodySmall;
  }
}

.contentsSubList {
  list-style: lower-alpha;
  margin-left: 48px;
}

.mainList {
  list-style: inside decimal;
  li {
    font-weight: $bold;
    margin-bottom: 12px;
    font-size: $body;
    margin-top: 24px;
  }
}

.subList {
  margin-left: 48px;
  list-style: lower-roman;
  li {
    font-weight: $regular;
    font-size: $bodyMedium;
  }
}

.mainUL {
  list-style: disc;
  margin-left: 48px;
  li {
    font-weight: $regular;
    font-size: $bodyMedium;
  }
}

.subUL {
  margin-left: 48px;
  list-style: circle;
  li {
    font-weight: $regular;
    font-size: $bodyMedium;
  }
}
