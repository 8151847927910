@import "../../Styles/theme.scss";

.table {
  display: flex;
  width: 100%;
  border: 1px solid $neutral2;
  border-radius: 4px 4px 0 0;
  flex-direction: column;
  color: $neutral7;
  line-height: 18px;
}

.table_header {
  display: flex;
  border-radius: 4px 4px 0 0;
  width: 100%;
  align-items: center;
  background-color: $neutral2;
  th {
    color: $neutral7;
    padding: 18px 0 18px 22px;
    font-size: $bodySmall;
    font-weight: $mediumWeight;
    flex: 1;
    box-sizing: border-box;
    text-align: left;
  }
}

.no_reports {
  align-self: center;
  color: $neutral7;
  text-align: center;
  h2 {
    font-size: $heading3;
    margin-bottom: 16px;
    margin-top: 64px;
  }
}
