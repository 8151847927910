@import "../../Styles/theme.scss";

.notification_item_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $white;
  border-radius: 4px;
  padding: 15px;
  border: 0.5px solid $neutral2;
}

.clickable {
  @extend .notification_item_container;
  cursor: pointer;
}

.not_clickable {
  @extend .notification_item_container;
}

.notification_item_title {
  font-size: $bodySmall;
  line-height: 21px;
  color: $neutral7;
  margin-bottom: 4px;
}

.bold {
  font-weight: $mediumWeight;
}

.notification_item_icon_container {
  margin-top: 4px;
  margin-right: 14px;
}
