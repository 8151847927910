@import "../../Styles/theme.scss";

.no_data_container {
  display: flex;
  max-width: 224px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $neutral2;
  border-radius: 3px;
  padding: 18px;
}

.red_container {
  @extend .no_data_container;
  background-color: $risk4;
}

.no_data_text {
  font-weight: $mediumWeight;
  font-size: $body;
  color: $neutral7;
  margin-left: 18px;
}

.red_container_text {
  @extend .no_data_text;
  color: $white;
}
