@import "../../Styles/theme.scss";

.filter_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 372px;
  background-color: $white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: absolute;
  z-index: 50;
  min-height: 300px;
  top: 230px;
  left: 110px;
  cursor: pointer;
}

.filter_choice_item {
  background-color: $white;
  font-weight: $regular;
  font-size: $bodyExtraSmall;
  text-align: left;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 36px;
  z-index: 101;

  p {
    color: $neutral7;
  }
}

.filter_is_selected {
  background-color: $neutral2;
  font-weight: $mediumWeight;
}

.choice_label {
  padding-left: 12px;
}

@media (max-width: 760px) {
  .filter_modal_container {
    top: 260px;
    left: 100px;
  }
}

@media (max-width: 600px) {
  .filter_modal_container {
    top: 315px;
    left: 24px;
    width: calc(100vw - $spacing-xxl);
    font-size: $bodyExtraSmall;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 26px;
  }
}
