@import "../../Styles/theme.scss";

.page {
  padding: 0;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  h2 {
    text-transform: uppercase;
    font-weight: $bold;
    font-size: $heading-3;
    margin-bottom: 0;
  }
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.filters_section {
  display: flex;
  align-items: center;
}

.create_new_user_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    margin-right: 6px;
  }
}

.button {
  margin-right: 12px !important;
}

.usersGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.usersList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.usersList_header,
.usersList_header_lrg,
.usersList_header_sm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary6;
  padding: 12px;
  color: $white;
  border-radius: 10px 10px 0 0;
  div {
    flex: 1;
    margin-right: 12px;
  }
}

.usersList_header_sm {
  // when above 700px, don't show
  display: none;
}

@media (max-width: 720px) {
  .filters {
    flex-direction: column;
    margin-bottom: 0;
  }
  .filters_section {
    margin-bottom: 12px;
  }
}

// Make sure to coorindate this with the StaffList component media query
@media (max-width: 700px) {
  .usersList_header_sm {
    display: flex;
    align-items: left;
    div {
      flex: 1;
      margin-right: 0px;
    }
    .usersList_header_right {
      text-align: right;
    }
  }
  .usersList_header_lrg {
    display: none;
  }
}

@media (max-width: $mobile-break-point) {
  .usersGrid {
    justify-content: center;
    button {
      margin-bottom: 6px !important;
    }
  }
  .content {
    padding: 12px;
    h2 {
      margin-top: 14px;
    }
    button {
      margin-bottom: 6px !important;
    }
  }
}

@media (max-width: 305px) {
  .create_new_user_container {
    flex-direction: column;
    button {
      margin-bottom: 6px;
      width: 100%;
    }
  }
}
