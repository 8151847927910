@import "../../../Styles/theme.scss";

.button:active {
  border: 1px solid $primary3;
  color: $neutral7;
}

.backButton {
  &:hover {
    background-color: $primary1;
  }
  &:active {
    border: 1px solid $primary3;
    background-color: $primary3;
    color: $neutral7;
  }
}
