@import "../../Styles/theme.scss";

.questions_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.question_wrapper {
  padding-bottom: 20px;
}

.question {
  font-size: $body;
  padding-bottom: 20px;
  cursor: pointer;
}

.selected_question {
  @extend .question;
  color: $primary6;
  font-weight: $mediumWeight;
}

.unselected_question {
  @extend .question;
  color: $neutral5;
  font-weight: $regular;
}

hr {
  width: 100%;
  border-top: 0.5px solid $neutral2;
}

@media (max-width: $tablet-break-point) {
  .question_wrapper {
    padding-bottom: 8px;
  }
  .question {
    padding-bottom: 4px;
  }
}
