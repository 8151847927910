@import "../../Styles/theme.scss";

// staff and service user name
.lilli_support_email,
.clickable_name {
  font-size: 16px;
  font-weight: $mediumWeight;
  font-family: Inter;
  cursor: pointer;
  color: $primary6;
}

.error_message {
  padding-bottom: 24px;
  text-align: center;
  a {
    color: $primary6;
    font-weight: $mediumWeight;
  }
}

.splash_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.staff_splash_title {
  font-family: Inter;
  font-weight: $mediumWeight;
  font-size: $heading4;
  color: $neutral7;
  padding-top: 16px;
  padding-bottom: 16px;
}

.staff_splash_message {
  font-family: Inter;
  font-weight: $regular;
  font-size: 16px;
  color: $neutral5;
  text-align: center;
  padding-bottom: 32px;
}
