@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 24px;
  }
}

.form_input_error {
  color: $risk6;
  line-height: 21px;
  font-weight: $mediumWeight;
  font-size: 14px;
  margin-top: -12px; 
  // adjust margin-top so that it is the exact opposite of the margin-bottom of the FormInput
  // see the className: "input_container"
}
.back_to_login_button{
  display: flex;
  justify-content: center;
  width: 100%;
}