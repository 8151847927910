@import "../../Styles/theme.scss";

.stepperContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  p {
    color: $neutral6;
    font-weight: $mediumWeight;
    font-size: $bodyExtraSmall;
  }
}
