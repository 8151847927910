@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 0;
  p {
    font-family: Inter;
    margin: 12px 0;
  }
}

.form_separator {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $neutral2;
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.form_fields {
  font-family: Inter;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.field {
  div {
    font-size: $bodySmall;
  }
  .label {
    color: $neutral7;
  }
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
  color: $neutral7;
}

.submit {
  align-self: flex-end;
  margin-top: 12px !important;
  button {
    color: $neutral5;
  }
}
.error {
  color: red;
  font-size: $bodyExtraSmall;
}

.assign_users_to_staff_title {
  color: $neutral7;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 20px;
}

.select_box {
  width: 50%;
  background-color: $white;
  color: $neutral7;
  font-size: 16px;
}

.choose_a_service_user {
  color: $neutral7;
  font-size: 16px;
}

.service_users {
  display: flex;
  flex-wrap: wrap;
}

.service_user {
  padding: 6px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.deleteStaff {
  width: 15px;
  cursor: pointer;
  color: $neutral4;
  margin-right: 6px;
}
@media (max-width: $tablet-break-point) {
  .form_fields {
    grid-template-columns: 1fr;
  }
  .select_box {
    width: 100%;
  }
}
