@import "../../Styles/theme.scss";

.stepper {
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
}

.progress {
  width: 100%;
  height: 4px;
  background-color: $neutral2;
  position: absolute;
  top: 18px;
  left: 0;
}

.progress::-webkit-progress-value {
  transition: width 0.3s ease-in;
  background-color: $primary3;
}

.progress::-webkit-progress-bar {
  background-color: $neutral2;
}

.stepper_step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.stepper_step_blob {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $neutral2;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.stepper_step_blob_complete {
  @extend .stepper_step_blob;
  background-color: $primary3;
}

.stepper_step_label {
  position: absolute;
  text-wrap: nowrap;
  top: 42px;
  font-size: $bodyExtraSmall;
  font-weight: $mediumWeight;
}
