@import "../../Styles/theme.scss";

.card {
  display: flex;
  align-items: center;
  border-radius: none !important;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid $primary6;
  border-top: 0;
  padding: 12px;
  background-color: $white;
}

.card_link {
  cursor: pointer;
}

.card_element {
  flex: 1;
}

.serviceUser {
  display: flex;
}
.serviceUser_name {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.serviceUser_name_name {
  font-weight: bold;
  font-size: $baseFontSize;
  margin-left: 6px;
  margin-top: 2px;
}
