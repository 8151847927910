@import "../../Styles/theme.scss";

.insight {
  border: 1px solid $neutral2;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  min-height: 160px;
  max-width: 400px;
  min-width: 270px;
  p {
    margin: 0;
    font-size: $bodySmall;
    margin-top: 10px;
  }
}

.insight_unexpected {
  @extend .insight;
  border: 1px solid $unexpected3;
}

.insight_at_risk {
  @extend .insight;
  border: 1px solid $risk3;
}

.insight_hover {
  &:hover {
    cursor: pointer;
  }
}

.insight_offline {
  color: $neutral5;
}

.insight_top {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  h3 {
    margin: 0;
    margin-left: 12px;
    text-transform: none;
    font-weight: $mediumWeight;
    font-size: $bodyMedium;
  }
}

.insight_headline {
  font-size: $heading2;
  color: $neutral7;
  font-weight: $mediumWeight;
  margin-bottom: 12px;
}

@media (max-width: $mobile-break-point) {
  .insight {
    max-width: none;
  }
}
