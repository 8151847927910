@import "../../Styles/theme.scss";

.risk_status_container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}

.status_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.top,
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.top {
  margin-bottom: 12px;
}

// status boxes
.risk_status {
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.at_risk {
  @extend .risk_status;
  background: #e15b5b;
  border: 1px solid #e15b5b;
}

.unexpected {
  @extend .risk_status;
  background: $unexpected3;
  border: 1px solid $unexpected3;
}

.placeholder {
  min-width: 18px;
  max-width: 18px;
}

.label {
  font-family: Inter;
  font-weight: $mediumWeight;
  color: $neutral7;
  font-size: $bodyMedium;
  padding-left: 12px;
}

.description {
  color: #686868;
  font-size: $bodyMedium;
  padding-left: 12px;
}

@media (max-width: 400px) {
  .risk_status_container {
    min-width: unset;
    max-width: unset;
    width: 100%;
    padding: unset;
    padding-top: 12px;
  }
}
