@import "../../Styles/theme.scss";

.data {
  display: flex;
  justify-content: flex-start;
  color: $primary6;
  margin-bottom: 12px;
}

.data_item {
  display: flex;
  font-weight: 500;
  font-size: $bodySmall;
  svg {
    margin-right: 6px;
  }
}

.data_item:nth-child(2),
.data_item:nth-child(3) {
  margin-top: 8px;
}

.conditions {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

.condition,
.care_type {
  font-size: $bodyExtraSmall;
  line-height: 1;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: $regular;
}

.condition {
  color: $neutral7;
  background-color: $risk1;
}

.care_type {
  color: $neutral7;
  background-color: $secondary1;
}

.none_listed {
  color: #818181;
  font-size: 14px;
  margin-left: 6px;
}

.usernote {
  font-weight: normal;
  margin-top: 8px;
}

.data_pdf {
  flex-direction: column;
  div {
    margin-right: 3px;
    margin-bottom: 3px;
    div {
      display: flex;
      align-items: flex-start;
      svg {
        margin-right: 3px;
      }
    }
  }
}

@media (max-width: 600px) {
  .data {
    justify-content: center;
  }

  .service_user_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    text-align: center;
  }

  .data_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .condition,
  .care_type {
    margin: 0px 0px 6px 6px;
  }
}
