@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.email_input_field {
  margin-top: 20px;
}
.forgot_password_buttons {
  display: flex;
  flex-direction: column; 
  margin-top: 24px;
}

.form_input_error {
  color: $risk6;
  line-height: 21px;
  font-weight: $mediumWeight;
  font-size: 14px;
  margin-top: -12px; 
  // adjust margin-top so that it is the exact opposite of the margin-bottom of the FormInput
  // see the className: "input_container"
}

.reset_password_message_container {
  display: flex;
  flex-direction: column;
  button {
    align-items: flex-end;
  }
}