@import "../../Styles/theme.scss";

.hoverArea {
  position: fixed;
  width: 60px;
  left: 0;
  top: $header-height;
  bottom: 0;
  z-index: 300;
}

.drawer {
  position: fixed;
  width: 248px;
  max-width: 248px;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 400;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.25s ease;
  box-sizing: border-box;
}

.drawer_closed {
  width: $side-drawer-width;
  cursor: pointer;
  a {
    justify-content: center;
    padding-left: 0;
  }
}

.drawerNav {
  height: 47px;
  width: 47px;
  justify-content: flex-start;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: $white;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-left: 7.5px;
  padding-left: 13px;
  position: relative;
  overflow: hidden;
  &:hover {
    text-decoration: none;
    background-color: $neutral2;
  }
}

@keyframes growX {
  from {
    width: 47px;
  }
  to {
    width: 231px;
  }
}
.drawerNav_open {
  @extend .drawerNav;
  width: 231px;
}

.drawerNav_animate {
  animation: growX 0.25s ease both;
  animation-delay: 250ms;
}

.drawerNav_active {
  background-color: $primary3;
  cursor: default;
  &:hover {
    background-color: $primary3;
  }
}

.drawerNav_item {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 14px;
}

.drawerNav_item_text {
  margin-left: 16px;
  white-space: nowrap;
  color: $neutral4;
}

.drawerNav_item_text_active {
  color: #1e2524;
}

.drawer_logo {
  box-sizing: border-box;
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  z-index: 300;
  svg {
    margin-left: 14px;
    height: 30px;
  }
}

@media (max-width: $mobile-break-point) {
  .drawer_closed {
    overflow: hidden;
    width: 0;
  }
  .hoverArea {
    width: 0;
  }
}
