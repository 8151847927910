@import "../../Styles/theme.scss";

.behaviour {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $neutral7;
}

.behaviour_chart {
  width: 48%;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.chartSummary {
  /* If you change the height by X amount, then you will also need to adjust the values in two other places (by the same amount) inside of the VictoryFlightPathChart. 
     1) VictoryChart height={showingTooltip ? 400 : 300} -> change the RIGHT-hand value by the same amount
     2) VictoryChart padding - "top: showingTooltip ? 110 : 10" --> change the LEFT-hand value by the same amount
  */
  height: 100px;
}

.behaviour_context {
  width: 48%;
}

.behaviour_explanation {
  margin-bottom: 12px;
  font-size: $bodyMedium;
}

.behaviour_noData {
  color: $neutral4;
  margin: auto;
}

.dayDetails_info {
  display: flex;
  align-items: center;
  div {
    margin-left: 10px;
    color: $neutral5;
    font-size: $bodySmall;
  }
}

.dayChart {
  align-self: center;
}

@media (max-width: 850px) {
  .behaviour_explanation {
    margin-bottom: 18px;
  }
  .behaviour_chart {
    width: 100%;
    min-height: 200px;
    flex: none;
    margin-top: 24px;
  }

  .dayDetails {
    display: none;
  }

  .dayChart {
    margin-top: 40px;
  }

  .behaviour {
    flex-direction: column;
  }
  .behaviour_context {
    margin-right: 0;
    width: 100%;
  }
}
