@import "../../Styles/theme.scss";

.tooltip_title {
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
}

.tooltip_content {
  display: flex;
  font-size: $bodySmall;
  padding-bottom: 16px;
  padding-right: 8px;
}

.tooltip_content_right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.tooltip_content_right_title {
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  line-height: 1.4;
}

.line {
  margin: 13px 0;
}

.icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable_behaviour {
  color: $primary6;
  text-decoration: underline;
  cursor: pointer;
}