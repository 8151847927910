@import "../../Styles/theme.scss";

.card {
  background-color: $white;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 12px;
}

@media (max-width: $mobile-break-point) {
  .card {
    width: 100%;
  }
}
