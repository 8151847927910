@import "../../Styles/theme.scss";

.digits {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.six_digit_input {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
}

.otp_field {
  width: 42.6px;
  height: 42.6px;
  border: 0.91px solid $neutral3;
  border-radius: 2px;
  margin-right: 10px;
  font-size: 28px;
  text-align: center;
  color: $neutral7;
  font-weight: $regular;
  &:last-child {
    margin-right: 0px;
  }
}

.error {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  color: $risk5;
  align-self: center;
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  text-align: center;
}

@media (max-width: 320px) {
  .otp_field {
    width: 42px;
    height: 42px;
    margin-right: 5px;
    font-size: 30px;
  }
}

@media (max-width: 290px) {
  .otp_field {
    width: 39px;
    height: 39px;
    margin-right: 5px;
    font-size: 26px;
  }
}

input {
  background: $white;
}
// If you don't include webkit, Safari turns the background color to yellow
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}
input[data-autocompleted] {
  background-color: white !important;
}
// input:focus,
// input:active {
//   border: 1px solid $primary4 !important;
// }
