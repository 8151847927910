@import "../../Styles/theme.scss";

.sensor_sticker {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border: 1px solid $neutral2;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: $heading5;
  font-weight: $mediumWeight;
}
