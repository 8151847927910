@import "../../Styles/theme.scss";

// Background overlay
.backdrop_position {
  left: 60px; // so that it does not cover the navigation
  top: 63px; // so that it does not cover the header
}

// Page and Report containers
.page {
  align-items: stretch;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  padding: 0;
  max-height: calc(100vh - 65px);
}

.preview_form_group {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  z-index: 500; // So that the pull tab is clickable with the Background Overlay in effect. Loading spinner has a z-index of 600, so it needs to be less than that.
  transition: bottom 0.6s ease;
}

.close_form {
  bottom: -388px;
}

.pull_tab_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65px;
  min-height: 65px;
}

.pull_tab {
  cursor: pointer;
  box-shadow: 0px 0px 1px 1px $neutral2;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  border-radius: 4px 4px 0 0;
  width: 100px;
  z-index: 500; // So that the pull tab is clickable with the Background Overlay in effect. Loading spinner has a z-index of 600, so it needs to be less than that.
}

.report {
  overflow: auto;
  background-color: $neutral2;
  margin: 0px 0px;
  flex: 1;
}

/* 1019px x 1319px is our default size of a PDF */
.pdf {
  width: 1019px;
  height: 1319px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  overflow: hidden;
  page-break-after: always;
  position: relative;
}

.error_fetching_report {
  height: 100vh;
}

@media (min-width: 1399px) {
  .close_form {
    bottom: -361px;
  }
}

// on sizes above mobile the height is set dynamically
// as such, the below is a hack for getting the
// pull tab to be visisble on form close
@media (max-width: 1099px) {
  .close_form {
    bottom: -384px;
  }
}

@media (max-width: 800px) {
  .close_form {
    bottom: -421px;
  }
}

@media (max-width: 708px) {
  .close_form {
    bottom: -443px;
  }
}

// since we have set the max-height of the form
// to 80vh we can easily determine the amount to
// lower the form
@media (max-width: $mobile-break-point) {
  .page {
    padding: 0;
    max-height: calc(100vh - 60px);
    overflow: auto;
  }
  .report {
    width: 100%;
    flex: 1;
  }
  .preview_form_group {
    height: 80vh;
    max-height: 80vh;
  }
  .close_form {
    bottom: calc(-80vh + 70px);
  }
  /* 
    We need to increase the height of the the pull tab 
    otherwise they won't be visible on mobile devices.
    This issue doesn't get picked up when you run a simulated mobile device on the web.
    To make it visible, we need to: 
     - increase the height of the pull tab
     - adjust svg position so it is closer to the top
   
     This kicks in when the form is closed.
     When the form is shown, it has its normal height (see '.pull_tab_container' class above).
  */
  .increase_height {
    // ...of the pull tab
    height: 120px;
  }
  .pull_tab {
    // .. move the svg closer to the top
    svg {
      position: absolute;
      top: 20px;
    }
  }
  .backdrop_position {
    left: 0px; // navigation doesn't exist on mobile view
  }
}
