@import "../../Styles/theme.scss";

.avatar {
  width: 49px;
  height: 49px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9dd4d1;
}

.avatar_photo {
  width: 49px;
  height: 49px;
  border-radius: 4px;
  object-fit: cover;
}

.avatar_outerCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef4db;
}

.avatar_innerCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9dd4d1;
}
