@import "../../Styles/theme.scss";

.dateSelector {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 12px 27px;
  box-sizing: border-box;
}

.dateSelector_increment {
  display: flex;
  align-items: center;

  button {
    svg {
      color: $neutral7;
    }
  }
  button:first-child {
    margin-right: 24px;
  }
  button:nth-last-child(2) {
    margin-left: 24px;
  }
  button:nth-last-child(1) {
    margin-left: 24px;
  }
  .selectorButton:hover {
    background-color: $neutral1;
  }
}

.dateSelector_increment_date {
  text-align: center;
  font-size: $body;
  color: $neutral7;
  font-weight: $mediumWeight;
}

.dateSelector_increment_button {
  color: #d9d9d9;
  border-radius: 50%;
}

.dateSelector_mode {
  display: flex;
  button {
    margin-right: 5px;
  }
}

@media (max-width: 900px) {
  .dateSelector {
    padding: 12px 24px;
  }
}

@media (max-width: $mobile-break-point) {
  .dateSelector {
    padding: 20px 20px 20px 6px;
    background-color: $neutral1;
  }

  .dateSelector_mode {
    button {
      margin-right: 14px;
    }
    button:last-child {
      margin-right: 0;
    }
  }

  .dateSelector_increment {
    button:first-child {
      margin-right: 0;
    }
    button:last-child {
      margin-left: 0;
    }
  }
}
