@import "../../../Styles/theme.scss";

.sensor_squares_container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sensor_squares {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr)); 
  row-gap: 22px;
  padding-top: 16px;
}

.square {
  justify-self: start;
}

.sensor_squares_description {
  font-family: 'Inter';
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 22px;
  text-align: left;
  color: $neutral6;
}

