@import "../../Styles/theme.scss";

.header {
  height: $header-height;
  position: fixed;
  background-color: $white;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  z-index: 100;
}

.header_left {
  display: flex;
  align-items: center;
}

.header_left_logo {
  cursor: pointer;
  padding-left: 19px;
}

.header_right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.header_search {
  flex: 1;
  margin-right: 30px;
}

.header_element {
  position: relative;
  margin-right: 16px;
  color: $white;
  button {
    color: $white;
  }
}

.header_element_dot {
  position: absolute;
  right: 8px;
  top: 12px;
  width: 6px;
  height: 6px;
  background-color: $risk3;
  border-radius: 50%;
}

.header_profile {
  margin-right: 30px;
  margin-left: 12px;
}

@media (max-width: $mobile-break-point) {
  .header {
    border-bottom: 1px solid $neutral2;
  }
  .header_element {
    margin-right: 12px;
  }
}
