@import "../../Styles/theme.scss";

.page {
  padding: 0 !important;
}

.no_users_message {
  display: flex;
  margin: 0px 12px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.user_list_search_input {
  background: $neutral1 !important;
  border: 1px solid $neutral1 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 280px !important;
  height: 42px !important;
  input {
    background: $neutral1 !important;
    font-size: $bodyMedium;
  }
  input::placeholder {
    font-size: $bodyMedium;
  }
  svg {
    top: 8px;
  }
}

.mobile_search_input {
  width: 100% !important;
}

.new_user_button_container {
  padding: 12px;
}
button {
  .new_user_button {
    line-height: unset !important;
    span {
      line-height: unset !important;
    }
  }
}

.mobile_new_user_button {
  padding: 0px !important;
  line-height: unset !important;
  width: 64px !important;
  span {
    justify-content: center;
    span {
      line-height: unset !important;
      margin: unset !important;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  overflow-x: scroll;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 24px;
  margin-top: 18px;
  box-sizing: border-box;
  flex-direction: column;
}

.filters_top,
.filters_bottom {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  text-align: center;
}

.filters_top {
  margin-bottom: 12px;
}

.filter_button {
  border-radius: 3.38462px !important;
  padding: 0px 14px !important;
  margin-right: 12px !important;
  text-align: left;
  box-shadow: 0px 0px 1.9999998807907104px 0px rgba(0, 0, 0, 0.15);
  font-size: $bodySmall !important;
  svg {
    margin-left: 4px;
    padding-right: 6px;
    stroke: $neutral7;
  }
}

.mobile_filter_button {
  flex-grow: 3;
  margin-right: 12px !important;
  text-align: left;
  box-shadow: 0px 0px 1.9999998807907104px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3.38462px !important;
  span {
    justify-content: space-between;
    svg {
      stroke: $neutral7;
    }
  }
}

.active_filter_button {
  background-color: $primary3 !important;
  color: $white !important;
}

.inactive_filter_button {
  background-color: $white !important;
  border-color: $white !important;
  color: $neutral7 !important;
}

.mobile_table {
  min-width: none;
  margin-top: 14px !important;
  thead {
    align-items: center;
    align-self: center;
    padding: 20px 12px 20px 27px;
    background: $white;
    border: 1px solid #e7e6e3;
    border-radius: 4px 4px 0px 0px;
    color: $neutral7;
    font-weight: $mediumWeight;
    font-size: $bodyMedium;
  }
  th {
    padding: 22px 28px;
    text-align: left;
    border-radius: 0px;
  }
  .mobile_th_service_user_and_arrows,
  .mobile_th_risk_status_and_arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    .mobile_th_service_user_label,
    .mobile_th_risk_status_label {
      margin-right: 8px;
    }
  }
}

.mobile_search_filter_and_add_new_service_user,
.desktop_search_filter_and_add_new_service_user {
  width: 100%;
  background: $white;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  margin-top: 18px;
}

.mobile_search_filter_and_add_new_service_user {
  flex-direction: column !important;
}

.desktop_search_filter_and_add_new_service_user {
  flex-direction: row;
}

.mobile_filter_new_user_container {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  height: 38px;
  min-height: 38px;
}

.desktop_filter_and_search_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
}

.desktop_table {
  min-width: 800px;
  margin-top: 12px !important;
  thead {
    align-items: center;
    align-self: center;
    padding: 20px 12px 20px 27px;
    background: $white;
    border: 1px solid #e7e6e3;
    border-radius: 4px 4px 0px 0px;
    color: $neutral7;
    font-weight: $mediumWeight;
    font-size: $bodyMedium;
  }
  th {
    padding: 28px;
    text-align: left;
    border-radius: 0px;
  }
  .th_service_user {
    width: 180px;
    .th_service_user_label {
      margin-right: 8px;
    }
  }
  .th_service_user_and_arrows,
  .th_installation_and_arrows,
  .th_risk_status_and_arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  .th_care_id {
    width: 120px;
  }

  .th_staff {
    width: 160px;
  }

  .th_installation_and_arrows {
    width: 140px;
    .th_installation_label {
      margin-right: 8px;
    }
  }

  .th_risk_status {
    width: 180px;
    .th_risk_status_label {
      margin-right: 8px;
    }
  }
}

@media (max-width: 920px) {
  .desktop_table {
    min-width: 640px;
    th {
      padding: 20px 28px;
      .th_service_user {
        width: 100px;
      }
      .th_care_id,
      .th_installation_and_arrows {
        width: 0px;
      }
    }
  }
}

@media (max-width: 840px) {
  .desktop_table {
    min-width: 0px;
    .th_installation_and_arrows,
    .th_care_id,
    .th_installation_and_arrows {
      width: 0px;
    }
  }
}

@media (max-width: 600px) {
  .content {
    padding: 13px;
  }
  td {
    padding: 24px 24px 18px 24px;
  }
}

@media (max-width: 400px) {
  .mobile_table {
    th {
      padding: 16px 22px 16px 28px;
    }
    td {
      padding: 16px 16px 8px 22px;
    }
  }
  .mobile_th_risk_status {
    padding: 16px 22px 16px 12px;
  }
}

@media (max-width: 320px) {
  .mobile_table {
    th {
      padding: 16px 16px 16px 28px;
    }
    td {
      padding: 16px 16px 10px 18px;
      p {
        padding: 6px;
      }
    }
  }
}

@media (max-width: 300px) {
  .mobile_th_risk_status {
    display: none;
  }
  .user_list_search_input {
    input::placeholder {
      font-size: $bodySmall;
    }
  }
}
