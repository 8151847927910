@import "../../Styles/theme.scss";

.tooltip_title {
  font-weight: $mediumWeight;
}

.line {
  margin: 3px 0;
}
.table {
  display: flex;
  border: 1px solid $neutral2;
  border-radius: 4px;
  flex-direction: column;
  color: $neutral7;
  line-height: 18px;
}

.table_row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $neutral2;
  &:hover {
    background-color: $neutral1;
    cursor: pointer;
  }
}

.table_row_element {
  display: flex;
  padding: 23px 0 23px 27px;
  font-size: $bodySmall;
  width: 140px;
  box-sizing: border-box;
}

.bold {
  font-weight: $mediumWeight;
}

.service_users {
  @extend .table_row_element;
  position: relative;
}
