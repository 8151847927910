@import "../../Styles/theme.scss";

.active_filter {
  background-color: $white;
  color: $primary4;
  svg {
    stroke: $primary4;
  }
}
.inactive_filter {
  background-color: $white;
  border-color: $white;
  color: $neutral7;
}
.filter_button {
  color: $neutral7;
  border-radius: 4.38px;
  padding: 10px 18px;
  margin-right: 12px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  font-size: $bodySmall;

  svg {
    margin-left: 4px;
    padding-right: 6px;
    stroke: $neutral7;
  }
}

.filter_button:hover {
  color: $primary4;
  svg {
    stroke: $primary4;
    color: $primary4;
  }
}

.dashboard_filter_modal {
  top: 274px;
  left: 91px;
}

@media (max-width: $mobile-break-point) {
  .dashboard_filter_container {
    width: 100%;
  }
  .filter_button {
    width: 100%;
    margin-top: 20px;
  }
  .dashboard_filter_modal {
    left: 20px;
  }
}
