@import "../../Styles/theme.scss";

.page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  background-color: $neutral1;
}

.content {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  background: $white;
  align-items: flex-start;
}

.content_empty {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 200px;
}

.search {
  margin-bottom: 12px;
  width: 80%;
  max-width: 740px;
  box-sizing: border-box;
  display: flex;
  margin-top: 18px;
  align-items: center;
}

.search_input {
  background: $white !important;
  border: 1px solid $neutral3 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 100% !important;
  margin-right: 8px;
  height: 45px !important;
  flex: 1;
  input {
    background: $white !important;
    font-size: $bodyMedium;
  }
  input::placeholder {
    font-size: $bodyMedium;
  }
  svg {
    top: 8px;
  }
}

.filters {
  display: flex;
  margin-bottom: 12px;
}
