@import "../../Styles/theme.scss";

input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral3;
  border-radius: 6px;
  font-size: $bodyMedium;
  display: flex;
  align-items: center;
  position: relative;
  background: $white;
  color: $neutral7;
}

// If you don't include webkit, Safari turns the background color to yellow
input:-webkit-autofill,
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s !important;
}
input[data-autocompleted] {
    background-color: white !important;
}
// input:focus,
// input:active {
//   border: 1px solid $primary4 !important;
// }

.input_container {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}

.input_inner_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $neutral7;
}
.meets_criteria {
  input {
    border: 1px solid $primary3;
  }
}

.does_not_meet_criteria {
  input {
    border: 1px solid $risk3;
  }
}

.icon {
  position: absolute;
  bottom: 6px;
  left: 270px;
  cursor: pointer;
}

.incorrect_icon {
  position: absolute;
  bottom: 7px;
  left: 248px;
}

@media (max-width: 335px) {
  .icon {
    left: 260px;
  }
}

@media (max-width: 318px) {
  .icon {
    left: 240px;
  }
}

@media (max-width: 300px) {
  .icon {
    left: 220px;
  }
}