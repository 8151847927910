@import "../../Styles/theme.scss";

.modal {
  position: fixed;
  z-index: 500;
  background-color: $expected1;
  width: 50%;
  height: 55%;
  box-shadow: 1px 1px 1px $neutral4;
  padding: $desktop-padding;
  left: 25%;
  top: 30%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.modal_close {
  position: absolute;
  top: $mobile-padding;
  right: $mobile-padding;
  cursor: pointer;
  z-index: 300;
}

.modal_title {
  align-self: flex-start;
  font-size: $heading2;
}

.modal_context {
  align-self: flex-start;
  font-size: $small-font-size;
  margin-bottom: 24px;
}

@media (max-width: $mobile-break-point) {
  .modal {
    width: 90%;
    height: 70%;
    left: 5%;
    top: 15%;
    padding: $mobile-padding;
  }
}
