@import "../../Styles/theme.scss";

.pageTitleContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.pageTitle_title {
  font-size: $heading3;
  font-weight: $mediumWeight;
  color: $neutral7;
  margin-bottom: 6px;
  margin-top: 18px;
}
