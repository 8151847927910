@import "../../Styles/theme.scss";

.page {
  width: 100%;
  height: 100%;
  background-color: $neutral7;
}

/* 1019px x 1319px is our default size of a PDF */
.pdf {
  width: 1019px;
  height: 1318px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  page-break-after: always;
  position: relative;
}
