@import "../../Styles/theme.scss";

.qr_reader {
  margin: 6px 0;
  width: 300px;
  border-radius: 12px;
}

.qr_reader_video {
  div {
    border-radius: 12px;
  }
  video {
    border-radius: 12px;
  }
}

.qr_placeholder {
  @extend .qr_reader;
  height: 300px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.qr_placeholder_message {
  color: $neutral7;
  width: 100%;
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
}

.qr_scan_error {
  color: $risk5;
  text-align: center;
}

@media (max-width: $mobile-break-point) {
  .qr_reader {
    width: 100%;
  }
  .qr_placeholder_message {
    color: $neutral7;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }
}
