@import "../../Styles/theme.scss";

.notifications_container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  font-family: 'Inter';
}
.outer_container {
  padding: 0px 0px 44px 0px;
}
.subheading_medium {
  color: $neutral7;
  font-size: $subHeading;
  font-weight: $mediumWeight;
  line-height: 20px;
  margin-bottom: 10px;
  word-wrap: break-word;
}
.notification_explanation {
  color: $neutral6;
  font-size: $bodySmall;
  font-weight: $regular;
  line-height: 16px;
  word-wrap: break-word;
}
.inner_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top_section, .bottom_section {
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
}
.top_section {
  margin-bottom: 26px;
  padding-bottom: 26px;
  border-bottom: $neutral2 solid 1px;
};

.column_1 {
  width: 385px;
}
.column_2 {
  min-width: 100px;
  width: 183px;
}
.column_3, .column_4 {
  min-width: 100px;
  width: 183px;
}
.column_2, .column_3, .column_4 {
  margin-left: 80px;
}

.bottom_section {
  .column_1, .column_2, .column_3, .column_4 {
    color: $neutral6;
    font-size: $bodyMedium;
    font-weight: $regular;
    line-height: 20px;
    word-wrap: break-word;
  }
  .title {
    color: $neutral7;
    font-size: $body;
    line-height: 22px;
  }
  .description{
    margin-top: 10px;
    margin-bottom: 30px;
    word-wrap: break-word;
  }
}

.notifications_error_message {
  color: $risk6;
  margin-top: 14px;
}

@media (max-width: 1360px) {
  .column_2, .column_3, .column_4 {
    margin-left: 60px;
  }
  .column_2, .column_3 {
    width: 180px;
  }
}

@media (max-width: 1260px) {
  .column_2, .column_3 {
    margin-left: 50px;
  }
  .column_4 {
    margin-left: 20px;
  }
  .column_2, .column_3 {
    width: 175px;
  }
}

@media (max-width: 1240px) {
  .column_2, .column_3 {
    margin-left: 45px;
  }
  .column_4 {
    margin-left: 0px;
  }
  .column_2, .column_3 {
    width: 155px;
  }
}

@media (max-width: 1100px) {
  .column_2, .column_3{
    margin-left: 35px;
  }
  .column_2, .column_3 {
    width: 140px;
  }
}

@media (max-width: 1000px) {
  .column_2, .column_3 {
    margin-left: 20px;
  }
  .column_2, .column_3 {
    width: 120px;
  }
}

@media (max-width: 950px) {
  .column_1 {
    width: 200px;
  }
  .column_2, .column_3, .column_4 {
    width: 160px;
  }
}

.mobile_title {
  color: $neutral7;
  font-weight: $regular;
  font-size: $body;
  border-bottom: $neutral2 1px solid;
  margin-bottom: 14px;
}
.mobile_description {
  margin-bottom: 14px;
}

.mobile_notification_delivery {
  margin-bottom: 16px;
}

.mobile_details {
  font-size: $bodyMedium;
  color: $neutral6;
  margin-bottom: 12px;
}

.mobile_toggle {
  display: flex;
  justify-content: flex-end;
}